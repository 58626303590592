import React, { useEffect } from "react"

import {
  Label, Input, Container, Currency, Description, ErrorText,
} from "./styles"

function InputAmount(props) {
  const inputRef = React.createRef<HTMLInputElement>()
  const {
    value, onChange, label, isFetching, errorText,
  } = props

  useEffect(() => {
    if (isFetching) inputRef.current.blur()
  })

  return (
    <Container>
      {!!errorText && <ErrorText>{errorText}</ErrorText>}
      <Label>
        {label}
        <Input ref={inputRef} type="text" value={value} onChange={onChange} />
        <Currency>₽</Currency>
      </Label>
      <Description>Минимальная сумма 10 000 ₽</Description>
    </Container>
  )
}

export default InputAmount
