import React from "react"
import styled from "styled-components"
import {
  width, height, WidthProps, HeightProps,
} from "styled-system"

const Logo = styled.div<WidthProps & HeightProps>`
  background-image: url(/images/login-logo.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  ${width};
  ${height};
`

const render = ({ size }) => <Logo width={size} height={size} />

render.defaultProps = {
  size: "48px",
}

export default React.memo(render)
