import React from "react"
import is from "is_js"

import {
  LoaderBox,
  MiniLoader,
  OrderFactory,
  LoadMore,
  CustomTooltip,
} from "src/components"
import { MarketRepaymentOrdersListHugeQueryContainer } from "src/query"

import { parseOrder, setDataFromCession } from "src/utils"

import {
  List,
  OrderContainerItems,
  TitleWrap,
  Title,
  HeadTh,
  HeadTr,
  TableHead,
  Table,
  TableLoader,
  DesktopWrapper,
  MobileWrapper,
  TooltipText,
  TooltipLink,
  TooltipIcon,
} from "./styles"

import {
  MOCK_ORDERS, MOBILE_MOCK_ORDERS, TABLE, MOBILE,
} from "./constants"

const parseHugeOrdersData = ({ orders }) => {
  if (!is.existy(orders)) {
    return []
  }
  const { edges } = orders

  return edges
    .map((edge) => edge.node)
    .map(parseOrder)
    .map(setDataFromCession)
}

const Header = () => (
  <TitleWrap>
    <Title>Действующие займы</Title>
  </TitleWrap>
)

const Render = (props) => {
  const { isFetching } = props

  const [isLoading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setLoading(false)
  }, [props.list.orders && props.list.orders.edges])

  const handleLoad = () => {
    setLoading(true)
    props.load.handle()
  }

  const orders = [...parseHugeOrdersData({ orders: props.list.orders })]

  return (
    <>
      {(isFetching || orders.length > 0) && (
        <>
          <Header />
          <OrderContainerItems>
            <DesktopWrapper>
              {isFetching && (
                <TableLoader>
                  <MiniLoader margin="auto" />
                </TableLoader>
              )}
              <Table cellPadding="5">
                <TableHead>
                  <HeadTr>
                    <HeadTh width="18%">Название</HeadTh>
                    <HeadTh>№ заявки</HeadTh>
                    <HeadTh>
                      Рейтинг{" "}
                      <CustomTooltip
                        placement="bottom"
                        overlay={
                          <div style={{ width: "180px" }}>
                            <TooltipText>
                              Подробную информацию о расчете рейтинга можно
                              узнать{" "}
                              <TooltipLink to="/invest/scoring">
                                здесь
                              </TooltipLink>
                            </TooltipText>
                          </div>
                        }
                      >
                        <span>
                          <TooltipIcon />
                        </span>
                      </CustomTooltip>
                    </HeadTh>
                    <HeadTh>Сумма</HeadTh>
                    <HeadTh>Ставка</HeadTh>
                    <HeadTh>Дата получения</HeadTh>
                    <HeadTh>Дата погашения</HeadTh>
                    <HeadTh>Статус</HeadTh>
                  </HeadTr>
                </TableHead>
                <tbody>
                  {!isFetching
                    && orders.length > 0
                    && orders.map((data, index) => {
                      const Item = OrderFactory.createRepaymentOrder(
                        data,
                        TABLE,
                      )
                      return <Item key={index} />
                    })}

                  {isFetching
                    && [...Array(MOCK_ORDERS)].map((item, i) => {
                      const Item = OrderFactory.createRepaymentMock({}, TABLE)
                      return <Item key={i} />
                    })}
                </tbody>
              </Table>
            </DesktopWrapper>

            <MobileWrapper>
              <List>
                {!isFetching
                  && orders.length > 0
                  && orders.map((data, i) => {
                    const Item = OrderFactory.createMobileRepaymentOrder(
                      data,
                      MOBILE,
                    )
                    return <Item key={i} />
                  })}

                {isFetching
                  && [...Array(MOBILE_MOCK_ORDERS)].map((data, i) => {
                    const Item = OrderFactory.createMobileRepaymentMock(
                      {},
                      MOBILE,
                    )
                    return <Item key={i} />
                  })}
              </List>
            </MobileWrapper>
          </OrderContainerItems>

          <LoaderBox>
            {props.load.isNext && !isLoading && (
              <LoadMore onClick={handleLoad}>+ загрузить еще</LoadMore>
            )}
            {props.load.isNext && isLoading && <MiniLoader />}
          </LoaderBox>
        </>
      )}
    </>
  )
}

export default MarketRepaymentOrdersListHugeQueryContainer((props) => (
  <Render list={props.data} load={props.load} isFetching={props.isFetching} />
))
