import styled, { css } from "styled-components"
import { width } from "styled-system"

const state = (color) => css`
  border-color: ${color} !important;
  border-left-width: 4px;
  padding-left: 7px;
`

const Input = styled.input<any>`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: 5px;
  height: 40px;
  margin: 0;
  padding: 0 10px;
  ${width}

  &::placeholder {
    color ${({ theme }) => theme.colors.greyDark};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.greyDark};
  }

  &:disabled {
    opacity: .4;
  }

  ${({ error }) => error && state("red")};
  ${({ success }) => success && state("green")};
`

Input.defaultProps = {
  width: "100%",
}

export default Input
