import is from "is_js"
import React, { useEffect } from "react"

import {
  notifyGTM, parseOrder, setDataFromCession, parseOrdersData, pipe,
} from "src/utils"

import { OrderPositionProvider } from "src/context"
import { MarketOrdersListHugeQueryContainer } from "src/query"

import {
  LoaderBox,
  MiniLoader,
  OrderFactory,
  Translate,
  LoadMore,
  CustomTooltip,
} from "src/components"

import Mobile from "./Mobile"

import {
  OrderContainerItems,
  TitleWrap,
  Title,
  HeadTh,
  HeadTr,
  TableHead,
  Table,
  TableLoader,
  DesktopWrapper,
  TooltipIcon,
  TooltipText,
  TooltipLink,
} from "./styles"

import { MOCK_ORDERS, TABLE } from "./constants"

const splitArray = (arr, chunkLength = 20) => {
  if (!Array.isArray(arr)) return []

  const result = []

  for (let index = 0; index < arr.length; index += chunkLength) {
    const chunk = arr.slice(index, index + chunkLength)
    result.push(chunk)
  }

  return result
}

const sendGTMEvent = (orders) => {
  if (!Array.isArray(orders) || !orders.length) return

  const allImpressions = orders.map((order, index) => {
    const { id, cession, application } = order

    return {
      id,
      position: index + 1,
      name: application.shortTitle || application.data.companyName,
      category: cession && cession.isActive ? "Цессия" : "Первичная",
    }
  })

  // нужно отправлять порциями не более 20 штук
  const impressionsChunks = splitArray(allImpressions)

  impressionsChunks.forEach((impressions) => notifyGTM({
    eventAction: "push",
    eventCategory: "",
    extras: {
      ecommerce: {
        impressions,
      },
    },
  }))
}

// TODO :remove this mess later; remove ~DmityDanev
const parseHugeOrdersData = ({ orders }) => {
  if (!is.existy(orders)) {
    return []
  }
  const { edges } = orders

  return edges
    .map((edge) => edge.node)
    .map(parseOrder)
    .map(setDataFromCession)
}

const TableItem = React.memo((props: { data: any }) => {
  const data = pipe(parseOrder, setDataFromCession)(props.data)

  return <OrderFactory.tableOrder data={data} />
})

const Orders = (props) => {
  const [isLoading, setLoading] = React.useState(false)
  const orders = parseOrdersData(props.data)
  const { isFetching } = props

  const eventSent = React.useRef(false)
  useEffect(() => {
    if (eventSent.current) return

    sendGTMEvent(parseHugeOrdersData(props.data))
    eventSent.current = true
  }, [eventSent])

  useEffect(() => {
    setLoading(false)
  }, [props.data.orders && props.data.orders.edges])

  const handleLoad = () => {
    setLoading(true)
    props.load.handle()
  }

  return (
    <>
      {(isFetching || orders.length > 0) && (
        <>
          <TitleWrap>
            <Title>
              <Translate i18n="pages:user.market.requests" />
            </Title>
          </TitleWrap>

          <OrderContainerItems>
            <DesktopWrapper>
              {isFetching && (
                <TableLoader>
                  <MiniLoader margin="auto" />
                </TableLoader>
              )}

              <Table cellPadding="5">
                <TableHead>
                  <HeadTr>
                    <HeadTh width="18%">Название</HeadTh>
                    <HeadTh width="40px" />
                    <HeadTh>
                      Рейтинг{" "}
                      <CustomTooltip
                        placement="bottom"
                        overlay={
                          <div style={{ width: "180px" }}>
                            <TooltipText>
                              Подробную информацию о расчете рейтинга можно
                              узнать{" "}
                              <TooltipLink to="/invest/scoring">
                                здесь
                              </TooltipLink>
                            </TooltipText>
                          </div>
                        }
                      >
                        <span>
                          <TooltipIcon />
                        </span>
                      </CustomTooltip>
                    </HeadTh>
                    <HeadTh width="72px" textAlign="center" />
                    <HeadTh>Цель, ₽</HeadTh>
                    <HeadTh>Ставка</HeadTh>
                    <HeadTh>Срок</HeadTh>
                    <HeadTh>
                      Тип{" "}
                      <CustomTooltip
                        placement="bottom"
                        overlay={
                          <div>
                            <TooltipText>
                              ИГК - исполнение государственного контракта
                            </TooltipText>
                            <TooltipText>
                              ИКК - исполнение коммерческого контракта
                            </TooltipText>
                            <TooltipText>
                              ПОС - пополнение оборотных средств
                            </TooltipText>
                          </div>
                        }
                      >
                        <span>
                          <TooltipIcon />
                        </span>
                      </CustomTooltip>
                    </HeadTh>
                    <HeadTh>Погашение ОД</HeadTh>
                    <HeadTh width="120px">Сбор до</HeadTh>
                  </HeadTr>
                </TableHead>

                <tbody>
                  {!isFetching
                    && orders.length > 0
                    && orders.map((data, index) => (
                      <OrderPositionProvider key={index} value={index + 1}>
                        <TableItem data={data} />
                      </OrderPositionProvider>
                    ))}

                  {isFetching
                    && [...Array(MOCK_ORDERS)].map((item, i) => {
                      const Item = OrderFactory.createMock({}, TABLE, false)
                      return (
                        <OrderPositionProvider key={i} value={i + 1}>
                          <Item />
                        </OrderPositionProvider>
                      )
                    })}
                </tbody>
              </Table>
            </DesktopWrapper>
            <Mobile
              isFetching={isFetching}
              orders={orders}
            />
          </OrderContainerItems>

          <LoaderBox>
            {props.load.isNext && !isLoading && (
              <LoadMore onClick={handleLoad}>+ загрузить еще</LoadMore>
            )}
            {props.load.isNext && isLoading && <MiniLoader />}
          </LoaderBox>
        </>
      )}
    </>
  )
}

export default MarketOrdersListHugeQueryContainer((props) => (
  <Orders {...props} />
))
