import moment from "moment"
import i18next from "i18next"

import { getProperty } from "src/utils/tools"
import { ProfileType } from "src/constants/profiles"
import { MAX_CIRCULATION_AMOUNT } from "src/constants/system"

export const parseDate = (dateString) => {
  const { language } = i18next

  const date = moment(dateString).locale(language)
  return `${date.format("LL")}, ${date.fromNow()}`
}

export const parseYearString = (dateString) => {
  const { language } = i18next

  const date = moment(dateString).locale(language)
  return `${date.format("LL")}`
}

export const getProfileAccreditation = (profile) => {
  const {
    __typename: type,
    investorAccreditation,
    borrowerAccreditation,
  } = profile

  if (type === ProfileType.individual) {
    return investorAccreditation
  }

  if (type === ProfileType.juristic) {
    return borrowerAccreditation
  }

  return null
}

const bankAccountsFilterFn = (item) => !!Object.keys(item).length

export const getBankAccountsFromForeignProfile = (
  profile,
  foreignBankAccountsAlias = "foreignBankAccounts",
) => {
  const bankAccounts = getProperty(profile, "bankAccounts", [])
  const foreignBankAccounts = getProperty(profile, foreignBankAccountsAlias, [])

  return [
    ...bankAccounts.filter(bankAccountsFilterFn),
    ...foreignBankAccounts.filter(bankAccountsFilterFn),
  ]
}

export const getProfileDetailedBalance = (profile) => {
  if (!profile) return null

  const { accountBalance, accountBalanceDetailed } = profile

  if (Array.isArray(accountBalance)) return accountBalance
  if (Array.isArray(accountBalanceDetailed)) return accountBalanceDetailed

  return null
}

const emptyBalance = {
  blocked: 0,
  invested: 0,
  available: 0,
}

export const getByCurrencyFromDetailedBalance = (
  detailedBalances,
  currency,
) => {
  if (Array.isArray(detailedBalances)) {
    const balanceByCurrency = detailedBalances.find(
      (item) => item.currency === currency,
    )
    return balanceByCurrency || { currency, ...emptyBalance }
  }

  return null
}

/* eslint-disable no-underscore-dangle */
export const isBorrower = (profile) => [
  ProfileType.juristic,
  ProfileType.entrepreneur,
].includes(profile.__typename)
export const isJuristicBorrower = (profile) => profile.__typename === ProfileType.juristic
export const isRussianInvestor = (profile) => profile.__typename === ProfileType.individual
export const isRussianEntrepreneur = (profile) => profile.__typename === ProfileType.entrepreneur
export const isForeignInvestor = (profile) => profile.__typename === ProfileType.foreignIndividual
export const isInvestor = (profile) => profile.investor.canBeInvestor === true
export const isIndividualInvestor = (profile) => [
  ProfileType.individual,
  ProfileType.foreignIndividual,
  ProfileType.entrepreneur,
].includes(profile.__typename)

export const isRussianUser = (viewer) => viewer.country === "RU"

export const isForeignBankAccount = (bankAccount) => bankAccount.__typename === "ForeignBankAccount"
/* eslint-enable no-underscore-dangle */

export const isApprovedProfile = (profile) => profile.accreditation && profile.accreditation.status === "APPROVED"

export const isInvestorAccredited = (profile) =>
  !!profile.investor && !!profile.investor.accreditation && profile.investor.accreditation.status === "APPROVED"

export const isBorrowerAccredited = (profile) =>
  !!profile.borrower && !!profile.borrower.accreditation && profile.borrower.accreditation.status === "APPROVED"

export const isProfileAccredited = (profile) =>
  (isJuristicBorrower(profile) ? isBorrowerAccredited(profile) : isInvestorAccredited(profile))

export const getInvestorProfiles = (viewer) => {
  const { profiles = [] } = viewer
  return profiles.filter(isInvestor)
}

export const getIndividualInvestorProfiles = (viewer) => {
  const profiles = [
    ...(viewer.profiles || []),
    ...(viewer.foreignProfiles || []),
  ]
  return profiles.filter(isIndividualInvestor)
}

export const getProfileName = (profile) => {
  if (!profile) return ""

  const name = profile.name || `${profile.firstName} ${profile.lastName}`
  const entrepreneurPrefix = i18next.t("common:entrepreneur_prefix")

  return isRussianEntrepreneur(profile) ? `${entrepreneurPrefix} ${name}` : name
}

export function getSupportingDocumentsOnModeration(docs) {
  return docs.filter((doc) => doc.status === "PENDING")
}

export function isNotQualifiedInvestor(profile) {
  if (!isRussianInvestor(profile)) {
    return false
  }

  const { investor = {}, supportingDocuments = [] } = profile
  const { investedAmount = 0, qualified } = investor

  if (qualified || investedAmount < MAX_CIRCULATION_AMOUNT) {
    return false
  }

  const docs = getSupportingDocumentsOnModeration(supportingDocuments)

  if (docs.length > 0) {
    return false
  }

  return true
}

export function getProfileFullBalance(profile) {
  if (!profile) return 0

  const { accountBalanceDetailed } = profile

  return accountBalanceDetailed.reduce((acc, balance) => {
    const { blocked, invested, available } = balance
    return acc + blocked + invested + available
  }, 0)
}
