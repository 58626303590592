import React, { useContext, useMemo } from "react"
import { graphql } from "react-relay"
import {
  Switch, Route, Redirect, useHistory,
} from "react-router-dom"

import {
  isJuristicBorrower,
  getProfileFullBalance,
  isInvestorAccredited,
} from "src/utils"
import { CurrentProfileContext } from "src/context"
import {
  QueryRenderer,
  PortfolioMenus,
  PortfolioAnalytics,
  InvestorWelcomePage,
  PortfolioAutoInvest,
} from "src/components"

import {
  InvestmentActiveTable,
  InvestmentCompletedTable,
  InvestmentSucceededTable,
  InvestmentPaymentGraph,
  LoanDraftTable,
  LoanActiveTable,
  LoanCompletedTable,
  LoanSucceededTable,
  LoanPaymentGraph,
} from "../PortfolioTable/Tables"

import { PATH } from "../../molecules/PortfolioMenus/constants"

function PortfolioWithTable(props) {
  const { profile } = props

  const { isActive } = profile.investor?.autoInvestments || {}
  const isBorrowerProfile = useMemo(() => isJuristicBorrower(profile), [
    profile,
  ])

  const history = useHistory()

  function goToProposal(order) {
    if (
      order.status === "INITIAL"
      || order.status === "PENDING"
      || order.status === "DECLINED"
    ) {
      history.push(`/orders/${order.id}`)
    } else {
      history.push(`/market/${order.id}`)
    }
  }

  function getProposalLink(order) {
    if (
      order.status === "INITIAL"
      || order.status === "PENDING"
      || order.status === "DECLINED"
    ) {
      return `/orders/${order.id}`
    }
    return `/market/${order.id}`
  }

  function toInvest(order) {
    history.push(`/market/${order.id}`)
  }

  return (
    <>
      <PortfolioMenus autoInvestIsActive={isActive} profile={profile} />

      {isBorrowerProfile && (
        <Switch>
          <Route exact path={PATH.LOANS}>
            <LoanDraftTable
              toProposal={goToProposal}
              getProposalLink={getProposalLink}
              profile={profile}
            />
            <LoanActiveTable
              toProposal={goToProposal}
              getProposalLink={getProposalLink}
              profile={profile}
            />
            <LoanCompletedTable
              toProposal={goToProposal}
              getProposalLink={getProposalLink}
              profile={profile}
            />
            <LoanSucceededTable
              toProposal={goToProposal}
              getProposalLink={getProposalLink}
              profile={profile}
            />
          </Route>

          {!!profile?.investor?.isApproved && (
            <Switch>
              <Route exact path={PATH.INVESTMENTS}>
                <InvestmentActiveTable
                  profile={profile}
                  toProposal={goToProposal}
                  getProposalLink={getProposalLink}
                  toInvest={toInvest}
                  retry={props.retry}
                />
                <InvestmentCompletedTable
                  toProposal={goToProposal}
                  getProposalLink={getProposalLink}
                  profile={profile}
                />
                <InvestmentSucceededTable
                  toProposal={goToProposal}
                  getProposalLink={getProposalLink}
                  profile={profile}
                />
              </Route>
              <Route exact path={PATH.ANALYTICS}>
                <PortfolioAnalytics />
              </Route>
              <Route exact path={PATH.AUTOINVEST}>
                <PortfolioAutoInvest />
              </Route>
            </Switch>
          )}

          <Route exact path={PATH.PAYMENTS}>
            {!!profile?.investor?.isApproved && (
              <InvestmentPaymentGraph profile={profile} />
            )}
              <LoanPaymentGraph profile={profile} />
          </Route>

          <Redirect to={PATH.LOANS} />
        </Switch>
      )}

      {!isBorrowerProfile && (
        <Switch>
          <Route exact path={PATH.INVESTMENTS}>
            <InvestmentActiveTable
              toProposal={goToProposal}
              getProposalLink={getProposalLink}
              retry={props.retry}
              toInvest={toInvest}
              profile={profile}
            />
            <InvestmentCompletedTable
              toProposal={goToProposal}
              getProposalLink={getProposalLink}
              profile={profile}
            />
            <InvestmentSucceededTable
              toProposal={goToProposal}
              getProposalLink={getProposalLink}
              profile={profile}
            />
          </Route>

          <Route exact path={PATH.PAYMENTS}>
            <InvestmentPaymentGraph profile={profile} />
          </Route>

          <Route exact path={PATH.ANALYTICS}>
            <PortfolioAnalytics />
          </Route>

          <Route exact path={PATH.AUTOINVEST}>
            <PortfolioAutoInvest />
          </Route>

          <Redirect to={PATH.INVESTMENTS} />
        </Switch>
      )}
    </>
  )
}

function Portfolio(props) {
  const { node } = props

  if (!isJuristicBorrower(node)) {
    const { count: investmentCount } = node.investor.investments

    const isAccredited = isInvestorAccredited(node)
    const balance = getProfileFullBalance(node)

    if (!investmentCount && (!isAccredited || balance < 1)) {
      return (
        <InvestorWelcomePage
          profielId={node.id}
          investmentsCount={investmentCount}
          isAccredited={isAccredited}
          balance={balance}
        />
      )
    }
  }

  return <PortfolioWithTable profile={node} retry={props.retry} />
}

const query = graphql`
  query PortfolioQuery($profileId: ID!) {
    node(id: $profileId) {
      ... on UserProfile {
        id
        __typename
        ... on IndividualProfile {
          accountBalanceDetailed {
            available
            blocked
            invested
          }
          investor {
            isApproved
            accreditation {
              status
            }
            autoInvestments {
              isActive
            }
            investments {
              count
            }
          }
        }
        ... on EntrepreneurProfile {
          accountBalanceDetailed {
            available
            blocked
            invested
          }
          investor {
            isApproved
            accreditation {
              status
            }
            autoInvestments {
              isActive
            }
            investments {
              count
            }
          }
        }
        ... on ForeignIndividualProfile {
          accountBalanceDetailed {
            available
            blocked
            invested
          }
          investor {
            isApproved
            accreditation {
              status
            }
            autoInvestments {
              isActive
            }
            investments {
              count
            }
          }
        }
        ... on LegalEntityProfile {
          investor {
            isApproved
            accreditation {
              status
            }
            investments {
              count
            }
          }
        }
      }
    }
  }
`

function Render(props) {
  const history = useHistory()
  const { profile = {} } = useContext(CurrentProfileContext) as any
  const { id: profileId } = profile

  if (!profile || !profileId) {
    history.push("/profiles/create/individual")
  }

  return (
    <QueryRenderer
      {...props}
      query={query}
      variables={{
        profileId,
      }}
      render={Portfolio}
    />
  )
}

export default Render
