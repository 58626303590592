import React from "react"
import useCollapse from "react-collapsed"

import {
  Title, Description, List, TitleContainer,
} from "./styles"
import { ArrowIcon, CollapseButton, CollapsedContent } from "../Table/styles"
import { MOCK_ORDERS } from "../../../OrdersList/Lists/Market/constants"

const collapseStyles = {
  transitionDuration: ".3s",
  transitionTimingFunction: "cubic-bezier(0.250, 0.460, 0.450, 0.940)",
}

export default function (props) {
  const {
    title,
    descr,
    items,
    Render,
    isFetching,
    isExpanded = true,
    setExpanded,
    ...rest
  } = props

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
    expandStyles: collapseStyles,
    collapseStyles,
  })

  return (
    <div>
      {!!title && (
        <TitleContainer isExpanded={isExpanded}>
          <Title>{title}</Title>

          {!!setExpanded && (
            <CollapseButton
              {...getToggleProps({
                onClick: () => setExpanded((prevExpanded) => !prevExpanded),
              })}
              disabled={isFetching}
            >
              <ArrowIcon />
            </CollapseButton>
          )}
        </TitleContainer>
      )}

      <CollapsedContent {...getCollapseProps()}>
        {descr && <Description>{descr}</Description>}
        <List>
          {!isFetching
            && items.map((item, i) => <Render key={i} {...item} {...rest} />)}

          {isFetching
            && [...Array(MOCK_ORDERS)].map((item, i) => (
              <Render.placeholder key={i} />
            ))}
        </List>
      </CollapsedContent>
    </div>
  )
}
