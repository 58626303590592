import React from "react"

import { graphql, createPaginationContainer } from "react-relay"

import {
  QueryRenderer,
} from "src/components"
import { VISIBLE_ORDER_COMPLETED } from "../portfolio/constants"

// TODO: unused - вернуть позже или удалить окончательно
/* const MockLoader = (props) => (
  <Box py="60px">
    <Loader fontSize="14px" />
  </Box>
) */

const manageOrdersQuery = graphql`
  query MarketRepaymentOrdersHugeListQuery($pageSize: Int!, $after: Cursor) {
    ...MarketRepaymentOrdersHugeList_root
  }
`
const PaginatedOrderTable = createPaginationContainer(
  (props: any) => {
    const { hasNextPage } = props.root.orders.pageInfo
    const handleLoad = () => props.relay.hasMore()
      && !props.relay.isLoading()
      && props.relay.loadMore(VISIBLE_ORDER_COMPLETED)

    const Table = props.render({
      data: props.root,
      load: { handle: handleLoad, isNext: hasNextPage },
    })
    return <div>{Table}</div>
  },
  {
    root: graphql`
      fragment MarketRepaymentOrdersHugeList_root on Query {
        orders(
          first: $pageSize
          after: $after
          filter: { status: [COMPLETE], cession: false }
        ) @connection(key: "ManageOrdersRepaymentRelay_orders") {
          edges {
            node {
              id
              __typename
              ... on Order {
                createdAt
                succeededAt
                status
                statusFrom
                tags {
                  name
                }
                backgroundImage {
                  url
                }
                offer {
                  ... on OrderOffer {
                    id
                    data
                  }
                }
                application {
                  shortTitle
                  longTitle
                  externalMedium {
                    video {
                      url
                    }
                  }
                  ... on OrderApplication {
                    id
                    data
                  }
                }
                profile {
                  ... on UserProfile {
                    id
                    ... on EntrepreneurProfile {
                      creditRating {
                        rating
                      }
                    }
                    ... on LegalEntityProfile {
                      name
                      creditRating {
                        rating
                      }
                      _avatar {
                        url
                      }
                      borrower {
                        ticker
                      }
                    }
                  }
                }
                completedAt
                fee
                chain {
                  id
                  gatheredAmount
                  investorsCount
                }
                creditRating {
                  rating
                }
                paymentSchedule {
                  borrower {
                    items {
                      state
                      date
                    }
                    info {
                      state
                    }
                  }
                }
                cession {
                  due
                  amount
                  isActive
                  interestRate
                  borrowerName
                  originalChainId
                  avatar {
                    url
                  }
                  borrowerRating {
                    rating
                    ratedAt
                  }
                }
                karma {
                  conclusion
                  isInvestor
                }
                viewer {
                  investedAmount
                }
                provision {
                  items {
                    kind
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    query: manageOrdersQuery,
    getConnectionFromProps: (props) => props.root.orders,
    getFragmentVariables: (previousVars) => ({
      ...previousVars,
      pageSize: VISIBLE_ORDER_COMPLETED,
    }),
    getVariables: (props, paginationInfo) => ({
      pageSize: VISIBLE_ORDER_COMPLETED,
      after: paginationInfo.cursor,
    }),
  },
)

const HugeContainer = (Render) => (propss) => (
  <QueryRenderer
    {...propss}
    query={manageOrdersQuery}
    render={({ error, ...props }) => {
      if (error) {
        return (
          <div>
            <h1>Error!</h1>
            <br />
            {error}
          </div>
        )
      }

      if (props) {
        return <PaginatedOrderTable root={props} render={Render} />
      }

      return <Render isFetching={true} load={{}} data={{}} />
    }}
    renderNull={() => <Render isFetching={true} load={{}} data={{}} />}
    variables={{
      pageSize: VISIBLE_ORDER_COMPLETED,
    }}
  />
)

export default HugeContainer
