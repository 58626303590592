import styled, { css } from "styled-components"

import { Icons } from "src/components"

export const HeadTh = styled.th`
  font-weight: 300;
  padding-bottom: 13px;
`

export const HeadTr = styled.tr`
  margin-bottom: 13px;
  border-bottom: 1px solid #e5e5e5;
`

export const TableHead = styled.thead`
  font-family: "Geometria";
  font-size: 12px;
  line-height: 15px;
  color: rgba(74, 74, 74, 0.75);
  font-weight: normal;
  height: 30px;
`

export const Table = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  margin: 40px 0 0 0;
`

export const ArrowIcon = styled(Icons.LeftArrow)`
  transform: rotate(270deg);
  fill: ${({ theme }) => theme.colors.primaryBlack};
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  margin-left: 12px;
  border-radius: 50%;
  transition: transform 300ms;
  transition-timing-function: "cubic-bezier(0.250, 0.460, 0.450, 0.940);
`

export const TitleContainer = styled.div<any>`
  display: flex;
  align-items: center;
  margin-top: 70px;

  ${({ isExpanded }) => isExpanded
    && css`
      svg {
        transform: rotate(90deg);
      }
    `};
`

export const CollapseButton = styled.button`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin-left: 16px;
  
  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  :hover {
    ${ArrowIcon} {
      background-color: ${({ theme }) => theme.colors.fullBlacks[1]};
    }
  }
`

export const Title = styled.p`
  font-family: Geometria;
  font-size: 24px;
  line-height: 30px;
  margin: 0;
  color: rgba(74, 74, 74, 0.75);
  text-transform: uppercase;
  font-weight: 300;
`

export const CollapsedContent = styled.div`
  position: relative;
  width: 100%;
`

export const Description = styled.p`
  font-family: Geometria;
  font-size: 15px;
  line-height: 19px;
  color: #000000;
  margin: 0;
`

export const TableRow = styled.tr`
  font-family: Geometria;
  font-size: 16px;
  line-height: 20px;
  height: 76px;
  vertical-align: middle;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  color: #4a4a4a;

  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }
`
