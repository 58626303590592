import React from "react"
import Relay, { graphql } from "react-relay"

import { Forms } from "src/components"
import { Container, Title } from "./styles"

const profileToForm = (profile) => {
  switch (profile.__typename) {
    case "LegalEntityProfile":
      return Forms.trusted.create.legal
    case "EntrepreneurProfile":
      return Forms.trusted.create.entrepreneur
    default:
      return null
  }
}

function EditTrustedProfile(props) {
  const { profile, createOrder } = props

  const Form = profileToForm(profile)

  return (
    <Container>
      <Title>Данные заёмщика</Title>
      <Form
        createOrder={createOrder}
        isEditable={true}
        profile={profile}
        user={profile.user}
      />
    </Container>
  )
}

export default Relay.createFragmentContainer(EditTrustedProfile, {
  profile: graphql`
    fragment EditTrustedProfile_profile on UserProfile {
      id
      __typename
      user {
        id
        email
      }
      ... on LegalEntityProfile {
        name
        phone
        inn
        kpp
        ogrn
        iian
        borrower {
          publicEmail
        }
        address {
          country
          region
          area
          locality
          street
          house
          housing
          structure
          flat
          raw
          countryIsoCode
          okato
          settlement
          postalCode
          cityDistrict
        }
        actualAddress {
          country
          region
          area
          locality
          street
          house
          housing
          structure
          flat
          raw
          countryIsoCode
          okato
          settlement
          postalCode
          cityDistrict
        }
        business {
          main
          aux
          legalForm
          startedAt
        }
        passport {
          scans {
            id
            url
            filename
          }
        }
        executive {
          name
        }
      }
      ... on EntrepreneurProfile {
        lastName
        firstName
        middleName
        phone
        inn
        ogrn
        iian
        birthDate
        birthPlace
        isPublicOfficial
        sex
        isRussiaTaxResident
        borrower {
          publicEmail
        }
        address {
          country
          region
          area
          locality
          street
          house
          housing
          structure
          flat
          raw
          countryIsoCode
          okato
          settlement
          postalCode
          cityDistrict
        }
        actualAddress {
          country
          region
          area
          locality
          street
          house
          housing
          structure
          flat
          raw
          countryIsoCode
          okato
          settlement
          postalCode
          cityDistrict
        }
        passport {
          id
          series
          number
          issuedAt
          issuedBy {
            code
            name
          }
          postponeScans
          scans {
            id
            url
            filename
          }
        }
      }
    }
  `,
})
