import React from "react"

import { LogoutLink } from "src/components"

import Locales from "./Locales"
import UserSiteHeader from "./User"
import { Main, LeftContainer, RightContainer } from "./styles"

const render = (props) => (
  <Main>
    <LeftContainer>Alun</LeftContainer>
    <div>
      <Locales />
    </div>
    <RightContainer>{props.viewer && <LogoutLink />}</RightContainer>
  </Main>
)

render.User = UserSiteHeader

export default render
