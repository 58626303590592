/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SexEnum = "FEMALE" | "MALE" | "%future added value";
export type UpdateEntrepreneurProfileAsInvestorInput = {
    profileId: string;
    inn: string;
    psrnie: string;
    date?: string | null;
    place?: string | null;
    iian?: string | null;
    license?: EntrepreneurLicenseInput | null;
    address: AddressInput;
    passport: PassportInput;
    birthDate: string;
    birthPlace: string;
    isPublicOfficial: boolean;
    isRussiaTaxResident: boolean;
    shouldSkipValidation?: boolean | null;
    sex?: SexEnum | null;
    bic?: string | null;
    checkingAccount?: string | null;
};
export type EntrepreneurLicenseInput = {
    type?: string | null;
    numb?: string | null;
    date?: string | null;
    place?: string | null;
    duration?: string | null;
    typeList?: string | null;
};
export type AddressInput = {
    country?: string | null;
    region?: string | null;
    area?: string | null;
    locality?: string | null;
    street?: string | null;
    house?: string | null;
    housing?: string | null;
    structure?: string | null;
    flat?: string | null;
    raw?: string | null;
    countryIsoCode?: string | null;
    okato?: string | null;
    settlement?: string | null;
    postalCode?: string | null;
    cityDistrict?: string | null;
    unreliable?: boolean | null;
};
export type PassportInput = {
    series?: string | null;
    number?: string | null;
    issuedAt?: string | null;
    issuedBy?: PassportIssuerInput | null;
    scans: Array<string>;
    postponeScans?: boolean | null;
};
export type PassportIssuerInput = {
    code?: string | null;
    name?: string | null;
};
export type UpdateEntrepreneurProfileAsInvestorMutationVariables = {
    input: UpdateEntrepreneurProfileAsInvestorInput;
};
export type UpdateEntrepreneurProfileAsInvestorMutationResponse = {
    readonly updateEntrepreneurProfileAsInvestor: {
        readonly profile: {
            readonly id: string;
        };
    };
};
export type UpdateEntrepreneurProfileAsInvestorMutation = {
    readonly response: UpdateEntrepreneurProfileAsInvestorMutationResponse;
    readonly variables: UpdateEntrepreneurProfileAsInvestorMutationVariables;
};



/*
mutation UpdateEntrepreneurProfileAsInvestorMutation(
  $input: UpdateEntrepreneurProfileAsInvestorInput!
) {
  updateEntrepreneurProfileAsInvestor(input: $input) {
    profile {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateEntrepreneurProfileAsInvestorInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateEntrepreneurProfileAsInvestorPayload",
    "kind": "LinkedField",
    "name": "updateEntrepreneurProfileAsInvestor",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EntrepreneurProfile",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateEntrepreneurProfileAsInvestorMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateEntrepreneurProfileAsInvestorMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "UpdateEntrepreneurProfileAsInvestorMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateEntrepreneurProfileAsInvestorMutation(\n  $input: UpdateEntrepreneurProfileAsInvestorInput!\n) {\n  updateEntrepreneurProfileAsInvestor(input: $input) {\n    profile {\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a5631135b7ed20e0d9317a9dbdda1643';
export default node;
