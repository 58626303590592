import React from "react"

import {
  LoaderBox,
  MiniLoader,
  OrderFactory,
  LoadMore,
  CustomTooltip,
} from "src/components"
import { MarketOrdersListHugeQueryCessionContainer } from "src/query"

import {
  parseOrder, parseOrdersData, pipe, setDataFromCession,
} from "src/utils"

import {
  List,
  OrderContainerItems,
  TitleWrap,
  Title,
  HeadTh,
  HeadTr,
  TableHead,
  Table,
  TableLoader,
  DesktopWrapper,
  MobileWrapper,
  TooltipIcon,
  TooltipText,
  TooltipLink,
} from "./styles"

import {
  MOCK_ORDERS, MOBILE_MOCK_ORDERS, TABLE, MOBILE,
} from "./constants"

const Header = () => (
  <TitleWrap>
    <Title>Вторичный рынок</Title>
  </TitleWrap>
)

const TableItem = React.memo((props: { data: any }) => {
  const data = pipe(parseOrder, setDataFromCession)(props.data)
  return <OrderFactory.tableOrderCession data={data} />
})

const MobileItem = React.memo((props: { data: any }) => {
  const data = pipe(parseOrder, setDataFromCession)(props.data)
  return <OrderFactory.mobileOrderCession data={data} />
})

const Render = (props) => {
  const [isLoading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setLoading(false)
  }, [props.list.orders && props.list.orders.edges])

  const handleLoad = () => {
    setLoading(true)
    props.load.handle()
  }

  const { isFetching } = props

  const orders = parseOrdersData({ orders: props.list.orders })

  return (
    <>
      {(isFetching || orders.length > 0) && (
        <>
          <Header />
          <OrderContainerItems>
            <DesktopWrapper>
              {isFetching && (
                <TableLoader>
                  <MiniLoader margin="auto" />
                </TableLoader>
              )}

              <Table cellPadding="5">
                <TableHead>
                  <HeadTr>
                    <HeadTh width="18%">Название</HeadTh>
                    <HeadTh width="40px" />
                    <HeadTh>
                      Рейтинг{" "}
                      <CustomTooltip
                        placement="bottom"
                        overlay={
                          <div style={{ width: "180px" }}>
                            <TooltipText>
                              Подробную информацию о расчете рейтинга можно
                              узнать{" "}
                              <TooltipLink to="/invest/scoring">
                                здесь
                              </TooltipLink>
                            </TooltipText>
                          </div>
                        }
                      >
                        <span>
                          <TooltipIcon />
                        </span>
                      </CustomTooltip>
                    </HeadTh>
                    <HeadTh width="72px" textAlign="center" />
                    <HeadTh>Объём (остаток)</HeadTh>
                    <HeadTh>Цена</HeadTh>
                    <HeadTh>Доходность</HeadTh>
                    <HeadTh>
                      Тип{" "}
                      <CustomTooltip
                        placement="bottom"
                        overlay={
                          <div>
                            <TooltipText>
                              ИГК - исполнение государственного контракта
                            </TooltipText>
                            <TooltipText>
                              ИКК - исполнение коммерческого контракта
                            </TooltipText>
                            <TooltipText>
                              ПОС - пополнение оборотных средств
                            </TooltipText>
                          </div>
                        }
                      >
                        <span>
                          <TooltipIcon />
                        </span>
                      </CustomTooltip>
                    </HeadTh>
                    <HeadTh>Погашение ОД</HeadTh>
                    <HeadTh width="140px">Погашение займа</HeadTh>
                  </HeadTr>
                </TableHead>

                <tbody>
                  {!isFetching
                    && orders.length > 0
                    && orders.map((data, index) => <TableItem key={index} data={data} />)}

                  {isFetching
                    && [...Array(MOCK_ORDERS)].map((item, i) => {
                      const Item = OrderFactory.createMock({}, TABLE, true)
                      return <Item key={i} />
                    })}
                </tbody>
              </Table>
            </DesktopWrapper>

            <MobileWrapper>
              <List>
                {!isFetching && orders.length > 0 && orders.map((data, i) => <MobileItem key={i} data={data} />)}

                {isFetching
                  && [...Array(MOBILE_MOCK_ORDERS)].map((data, i) => {
                    const Item = OrderFactory.createMobileMock({}, MOBILE, true)
                    return <Item key={i} />
                  })}
              </List>
            </MobileWrapper>
          </OrderContainerItems>

          <LoaderBox>
            {props.load.isNext && !isLoading && (
              <LoadMore onClick={handleLoad}>+ загрузить еще</LoadMore>
            )}
            {props.load.isNext && isLoading && <MiniLoader />}
          </LoaderBox>
        </>
      )}
    </>
  )
}

export default MarketOrdersListHugeQueryCessionContainer((props) => (
  <Render
    environment={props.environment}
    list={props.data}
    load={props.load}
    isFetching={props.isFetching}
  />
))
