import React from "react"
import Relay, { graphql } from "react-relay"
import { Translate } from "src/components"

import { BankAccountStatuses } from "src/constants"
import {
  Container,
  LegalForm,
  Status,
  Name,
  Divider,
  Menu,
  MenuItem,
  Button,
  QualifiedStar,
  FlexContainer,
  NameContainer,
  MenuButton,
  ReferralLink,
  QualifiedTooltip,
  TooltipText,
} from "./styles"

import { ErrorIcon } from "./icons"

import {
  TAB, STATUS, allStatuses, allProfileTypes,
} from "./constants"
import { Link } from "../ProfileForm/styles"

const ProfileMenu = ({ profile, tab, changeTab }: any) => {
  const {
    accreditation,
    investor,
    name,
    __typename: profileType,
    bankAccounts,
    id: profileId,
  } = profile

  const currentStatus = allStatuses[accreditation.status]
  const currentType = allProfileTypes[profileType]

  return (
    <Container>
      <QualifiedTooltip id="qualified" effect="solid" delayHide={500}>
        <TooltipText>Квалифицированный инвестор</TooltipText>
      </QualifiedTooltip>

      <div>
        <FlexContainer>
          <LegalForm>{currentType}</LegalForm>

          {investor && investor.qualified && (
            <QualifiedStar data-tip data-for="qualified" />
          )}
        </FlexContainer>

        <NameContainer>
          <Name>{name}</Name>
          <Status color={currentStatus.color}>
            {currentStatus.name}{" "}
            {accreditation.status === STATUS.DECLINED && <ErrorIcon />}
          </Status>
        </NameContainer>
      </div>

      {accreditation.status === STATUS.INITIAL && (
        <Link to={`/accounts/${profile.id}/accreditation`}>
          <Button>РЕДАКТИРОВАТЬ</Button>
        </Link>
      )}
      <Divider />
      <Menu>
        <MenuItem>
          <MenuButton
            checked={tab === TAB.INFORMATION || tab === ""}
            onClick={() => changeTab(TAB.INFORMATION)}
          >
            Анкета
          </MenuButton>
        </MenuItem>
        <MenuItem>
          <MenuButton
            checked={tab === TAB.HISTORY}
            onClick={() => changeTab(TAB.HISTORY)}
          >
            История операций
          </MenuButton>
        </MenuItem>
        <MenuItem>
          <MenuButton
            disabled={accreditation.status !== STATUS.APPROVED}
            checked={tab === TAB.BANK}
            onClick={() => changeTab(TAB.BANK)}
          >
            Банковские реквизиты
            {bankAccounts[0]
              && bankAccounts[0].status === BankAccountStatuses.initial && (
                <ErrorIcon />
            )}
          </MenuButton>
        </MenuItem>
        {profileType !== "LegalEntityProfile" && (
          <MenuItem>
            <MenuButton
              checked={tab === TAB.TAXES}
              onClick={() => changeTab(TAB.TAXES)}
            >
              <Translate i18n="components:account.account_item.tabs.taxes.title" />
            </MenuButton>
          </MenuItem>
        )}

        <ReferralLink to={`/referral/${profileId}`}>
          Реферальная программа
        </ReferralLink>
      </Menu>
    </Container>
  )
}

export default Relay.createFragmentContainer(ProfileMenu, {
  profile: graphql`
    fragment ProfileMenu_profile on UserProfile {
      id
      __typename
      name
      bankAccounts {
        id
        status
        checkingAccount
        correspondentAccount
        bank {
          bic
          name
        }
      }
      ... on IndividualProfile {
        accreditation(role: INVESTOR) {
          status
        }
        investor {
          qualified
        }
      }
      ... on ForeignIndividualProfile {
        accreditation(role: INVESTOR) {
          status
        }
      }
      ... on EntrepreneurProfile {
        accreditation(role: INVESTOR) {
          status
        }
      }
      ... on LegalEntityProfile {
        accreditation(role: BORROWER) {
          status
        }
      }
    }
  `,
})
