/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type InvestmentHistoryEnum = "INVESTMENT" | "PURCHASE" | "SELL" | "%future added value";
export type RiskQueryVariables = {
    id: string;
};
export type RiskQueryResponse = {
    readonly node: {
        readonly id?: string;
        readonly __typename?: string;
        readonly accountBalanceDetailed?: ReadonlyArray<{
            readonly invested: number;
        }>;
        readonly investor?: {
            readonly investments: {
                readonly count: number;
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly amount: number;
                        readonly loan: number;
                        readonly history: ReadonlyArray<{
                            readonly type: InvestmentHistoryEnum;
                            readonly amount: number;
                        }> | null;
                    };
                }>;
            };
        } | null;
    };
};
export type RiskQuery = {
    readonly response: RiskQueryResponse;
    readonly variables: RiskQueryVariables;
};



/*
query RiskQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ... on UserProfile {
      id
      __typename
      accountBalanceDetailed {
        invested
      }
      ... on IndividualProfile {
        investor {
          investments(filter: {status: [COMPLETE]}) {
            count
            edges {
              node {
                amount
                loan
                history {
                  type
                  amount
                }
                id
              }
            }
          }
        }
      }
      ... on EntrepreneurProfile {
        investor {
          investments(filter: {status: [COMPLETE]}) {
            count
            edges {
              node {
                amount
                loan
                history {
                  type
                  amount
                }
                id
              }
            }
          }
        }
      }
      ... on ForeignIndividualProfile {
        investor {
          investments(filter: {status: [COMPLETE]}) {
            count
            edges {
              node {
                amount
                loan
                history {
                  type
                  amount
                }
                id
              }
            }
          }
        }
      }
      ... on LegalEntityProfile {
        investor {
          investments(filter: {status: [COMPLETE]}) {
            count
            edges {
              node {
                amount
                loan
                history {
                  type
                  amount
                }
                id
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileAccountBalance",
  "kind": "LinkedField",
  "name": "accountBalanceDetailed",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invested",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "status": [
        "COMPLETE"
      ]
    }
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "loan",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "InvestmentHistory",
  "kind": "LinkedField",
  "name": "history",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    (v7/*: any*/)
  ],
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": (v5/*: any*/),
    "concreteType": "InvestmentConnection",
    "kind": "LinkedField",
    "name": "investments",
    "plural": false,
    "selections": [
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "InvestmentEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Investment",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "investments(filter:{\"status\":[\"COMPLETE\"]})"
  }
],
v11 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Investor",
    "kind": "LinkedField",
    "name": "investor",
    "plural": false,
    "selections": (v10/*: any*/),
    "storageKey": null
  }
],
v12 = [
  {
    "alias": null,
    "args": (v5/*: any*/),
    "concreteType": "InvestmentConnection",
    "kind": "LinkedField",
    "name": "investments",
    "plural": false,
    "selections": [
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "InvestmentEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Investment",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "investments(filter:{\"status\":[\"COMPLETE\"]})"
  }
],
v13 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Investor",
    "kind": "LinkedField",
    "name": "investor",
    "plural": false,
    "selections": (v12/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RiskQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IndividualProfileInvestor",
                "kind": "LinkedField",
                "name": "investor",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              }
            ],
            "type": "IndividualProfile"
          },
          {
            "kind": "InlineFragment",
            "selections": (v11/*: any*/),
            "type": "EntrepreneurProfile"
          },
          {
            "kind": "InlineFragment",
            "selections": (v11/*: any*/),
            "type": "ForeignIndividualProfile"
          },
          {
            "kind": "InlineFragment",
            "selections": (v11/*: any*/),
            "type": "LegalEntityProfile"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RiskQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IndividualProfileInvestor",
                "kind": "LinkedField",
                "name": "investor",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": null
              }
            ],
            "type": "IndividualProfile"
          },
          {
            "kind": "InlineFragment",
            "selections": (v13/*: any*/),
            "type": "EntrepreneurProfile"
          },
          {
            "kind": "InlineFragment",
            "selections": (v13/*: any*/),
            "type": "ForeignIndividualProfile"
          },
          {
            "kind": "InlineFragment",
            "selections": (v13/*: any*/),
            "type": "LegalEntityProfile"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "RiskQuery",
    "operationKind": "query",
    "text": "query RiskQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on UserProfile {\n      id\n      __typename\n      accountBalanceDetailed {\n        invested\n      }\n      ... on IndividualProfile {\n        investor {\n          investments(filter: {status: [COMPLETE]}) {\n            count\n            edges {\n              node {\n                amount\n                loan\n                history {\n                  type\n                  amount\n                }\n                id\n              }\n            }\n          }\n        }\n      }\n      ... on EntrepreneurProfile {\n        investor {\n          investments(filter: {status: [COMPLETE]}) {\n            count\n            edges {\n              node {\n                amount\n                loan\n                history {\n                  type\n                  amount\n                }\n                id\n              }\n            }\n          }\n        }\n      }\n      ... on ForeignIndividualProfile {\n        investor {\n          investments(filter: {status: [COMPLETE]}) {\n            count\n            edges {\n              node {\n                amount\n                loan\n                history {\n                  type\n                  amount\n                }\n                id\n              }\n            }\n          }\n        }\n      }\n      ... on LegalEntityProfile {\n        investor {\n          investments(filter: {status: [COMPLETE]}) {\n            count\n            edges {\n              node {\n                amount\n                loan\n                history {\n                  type\n                  amount\n                }\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '942a62fe8f11604fb4a70084ed3f263e';
export default node;
