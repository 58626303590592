import React from "react"

import { OrderFactory } from "src/components"

import { pipe, parseOrder, setDataFromCession } from "src/utils"
import { MobileWrapper, List } from "./styles"
import { MOBILE_MOCK_ORDERS, MOBILE } from "./constants"

const MobileItem = React.memo((props: { data: any }) => {
  const data = pipe(parseOrder, setDataFromCession)(props.data)

  return <OrderFactory.mobileOrder data={data} />
})

const Mobile = ({ isFetching, orders }) => {
  const show = !isFetching && orders.length > 0
  return (
    <MobileWrapper>
      <List>
        {show && orders.map((order, i) => <MobileItem key={i} data={order} />)}

        {isFetching
          && [...Array(MOBILE_MOCK_ORDERS)].map((data, i) => {
            const Item = OrderFactory.createMobileMock({}, MOBILE, false)
            return <Item key={i} />
          })}
      </List>
    </MobileWrapper>
  )
}

export default Mobile
