import React from "react"

import { graphql, createPaginationContainer } from "react-relay"

import {
  QueryRenderer,
} from "src/components"
import { VISIBLE_ORDER } from "../portfolio/constants"

// TODO: unused - вернуть позже или удалить окончательно
/* const MockLoader = (props) => (
  <Box py="60px">
    <Loader fontSize="14px" />
  </Box>
) */
const manageOrdersQuery = graphql`
  query MarketOrdersHugeListCessionQuery($pageSize: Int!, $after: Cursor) {
    ...MarketOrdersHugeListCession_root
  }
`
const PaginatedOrderTable = createPaginationContainer(
  (props: any) => {
    const { hasNextPage } = props.root.orders.pageInfo
    const handleLoad = () => props.relay.hasMore()
      && !props.relay.isLoading()
      && props.relay.loadMore(VISIBLE_ORDER)
    const Table = props.render({
      data: props.root,
      load: { handle: handleLoad, isNext: hasNextPage },
      environment: props.relay.environment,
    })
    return <div>{Table}</div>
  },
  {
    root: graphql`
      fragment MarketOrdersHugeListCession_root on Query {
        orders(first: $pageSize, after: $after, filter: { cession: true })
          @connection(key: "ManageOrdersCessionRelay_orders") {
          ...OrdersCount_orders
          edges {
            node {
              id
              __typename
              ... on Order {
                paymentSchedule {
                  borrower {
                    info {
                      state
                      penalty
                      pastdue_days
                    }
                  }
                }
                viewer {
                  hasIntent
                  investedAmount
                }
                tags {
                  name
                }
                backgroundImage {
                  url
                }
                createdAt
                status
                externalUrl
                fee
                profile {
                  ... on UserProfile {
                    id
                    ... on EntrepreneurProfile {
                      creditRating {
                        rating
                      }
                    }
                    ... on LegalEntityProfile {
                      name
                      inn
                      ogrn
                      borrower {
                        ticker
                      }
                      executive {
                        name
                      }
                    }
                    creditRating {
                      rating
                    }
                    _avatar {
                      url
                    }
                  }
                }
                offer {
                  ... on OrderOffer {
                    id
                    data
                    declineReason
                    receivedAt
                  }
                }
                application {
                  shortTitle
                  longTitle
                  description
                  externalMedium {
                    video {
                      url
                    }
                  }
                  ... on OrderApplication {
                    id
                    data
                    declineReason
                    receivedAt
                  }
                }
                confirmedAt
                succeededAt
                creditRating {
                  rating
                }
                otherDocuments {
                  attachment {
                    url
                    filename
                  }
                  description
                }
                cession {
                  due
                  amount
                  isActive
                  interestRate
                  borrowerName
                  originalChainId
                  avatar {
                    url
                  }
                  originalOrder {
                    paymentSchedule {
                      borrower {
                        items {
                          state
                          date
                        }
                      }
                    }
                    profile {
                      ... on LegalEntityProfile {
                        name
                        inn
                        ogrn
                        borrower {
                          ticker
                        }
                        executive {
                          name
                        }
                      }
                    }
                  }
                  borrowerRating {
                    rating
                    ratedAt
                  }
                }
                skipOffer
                karma {
                  isInvestor
                  isTrusted
                }
                chain {
                  id
                  gatheredAmount
                  investorsCount
                }
                guarantors {
                  attachment {
                    url
                    filename
                  }
                  name
                  description
                }
                status
                provision {
                  items {
                    kind
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    query: manageOrdersQuery,
    getConnectionFromProps: (props) => props.root.orders,
    getFragmentVariables: (previousVars) => ({
      ...previousVars,
      pageSize: VISIBLE_ORDER,
    }),
    getVariables: (props, paginationInfo) => ({
      pageSize: VISIBLE_ORDER,
      after: paginationInfo.cursor,
    }),
  },
)

const HugeContainer = (Render) => (propss) => (
  <QueryRenderer
    {...propss}
    query={manageOrdersQuery}
    render={({ error, ...props }) => {
      if (error) {
        return (
          <div>
            <h1>Error!</h1>
            <br />
            {error}
          </div>
        )
      }

      if (props) {
        return <PaginatedOrderTable root={props} render={Render} />
      }

      return <Render isFetching={true} load={{}} data={{}} />
    }}
    renderNull={() => <Render isFetching={true} load={{}} data={{}} />}
    variables={{
      pageSize: VISIBLE_ORDER,
    }}
  />
)

export default HugeContainer
