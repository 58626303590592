import React from "react"

import {
  Box, Carousel, Translate, SliderModal,
} from "src/components"

import { Subtitle } from "../styles"
import { renderImageItem } from "../elements"

class ItemImages extends React.PureComponent<any, any> {
  state = {
    modalOpened: false,
    initialSlide: 0,
  }

  onModalClose = () => {
    this.setState({ modalOpened: false })
  }

  onModalOpen = (item, idx) => {
    this.setState({
      modalOpened: true,
      initialSlide: idx,
    })
  }

  render() {
    const { images } = this.props

    if (!images.length) return null

    const { modalOpened, initialSlide } = this.state

    return (
      <Box mb="25px">
        <Subtitle>
          <Translate i18n="components:account.order_item.provision.images" />
        </Subtitle>
        <Carousel
          items={images}
          renderItem={renderImageItem}
          onItemClick={this.onModalOpen}
        />
        {modalOpened && (
          <SliderModal
            images={images}
            initialSlide={initialSlide}
            onClose={this.onModalClose}
          />
        )}
      </Box>
    )
  }
}

export default ItemImages
