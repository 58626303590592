/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AccreditationInfoQueryVariables = {
    id: string;
};
export type AccreditationInfoQueryResponse = {
    readonly node: {
        readonly id: string;
        readonly __typename: string;
        readonly name?: string;
        readonly isApproved?: boolean;
        readonly inn?: string | null;
        readonly iian?: string | null;
        readonly kpp?: string | null;
        readonly ogrn?: string | null;
        readonly business?: {
            readonly main: string | null;
            readonly aux: string | null;
            readonly legalForm: string | null;
            readonly startedAt: string | null;
        } | null;
        readonly executive?: {
            readonly name: string;
        } | null;
        readonly address?: {
            readonly raw: string | null;
            readonly country: string | null;
            readonly region: string | null;
            readonly area: string | null;
            readonly locality: string | null;
            readonly street: string | null;
            readonly house: string | null;
            readonly housing: string | null;
            readonly structure: string | null;
            readonly flat: string | null;
            readonly okato: string | null;
            readonly settlement: string | null;
            readonly postalCode: string | null;
            readonly cityDistrict: string | null;
        } | null;
        readonly actualAddress?: {
            readonly raw: string | null;
            readonly country: string | null;
            readonly region: string | null;
            readonly area: string | null;
            readonly locality: string | null;
            readonly street: string | null;
            readonly house: string | null;
            readonly housing: string | null;
            readonly structure: string | null;
            readonly flat: string | null;
            readonly okato: string | null;
            readonly settlement: string | null;
            readonly postalCode: string | null;
            readonly cityDistrict: string | null;
        } | null;
        readonly passport?: {
            readonly scans: ReadonlyArray<{
                readonly id: string;
                readonly url: string;
                readonly filename: string;
            }>;
        } | null;
        readonly borrower?: {
            readonly publicEmail: string | null;
        } | null;
    };
};
export type AccreditationInfoQuery = {
    readonly response: AccreditationInfoQueryResponse;
    readonly variables: AccreditationInfoQueryVariables;
};



/*
query AccreditationInfoQuery(
  $id: ID!
) {
  node(id: $id) {
    id
    __typename
    ... on LegalEntityProfile {
      name
      isApproved
      inn
      iian
      kpp
      ogrn
      business {
        main
        aux
        legalForm
        startedAt
      }
      executive {
        name
      }
      address {
        raw
        country
        region
        area
        locality
        street
        house
        housing
        structure
        flat
        okato
        settlement
        postalCode
        cityDistrict
      }
      actualAddress {
        raw
        country
        region
        area
        locality
        street
        house
        housing
        structure
        flat
        okato
        settlement
        postalCode
        cityDistrict
      }
      passport {
        scans {
          id
          url
          filename
        }
        id
      }
      borrower {
        publicEmail
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isApproved",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inn",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "iian",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kpp",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ogrn",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Business",
  "kind": "LinkedField",
  "name": "business",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "main",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aux",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalForm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startedAt",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "executive",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
},
v12 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "raw",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "country",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "region",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "area",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "locality",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "street",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "house",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "housing",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "structure",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "flat",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "okato",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "settlement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "postalCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "cityDistrict",
    "storageKey": null
  }
],
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "actualAddress",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "scans",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filename",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Borrower",
  "kind": "LinkedField",
  "name": "borrower",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicEmail",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccreditationInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Passport",
                "kind": "LinkedField",
                "name": "passport",
                "plural": false,
                "selections": [
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              (v16/*: any*/)
            ],
            "type": "LegalEntityProfile"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccreditationInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Passport",
                "kind": "LinkedField",
                "name": "passport",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v16/*: any*/)
            ],
            "type": "LegalEntityProfile"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AccreditationInfoQuery",
    "operationKind": "query",
    "text": "query AccreditationInfoQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    id\n    __typename\n    ... on LegalEntityProfile {\n      name\n      isApproved\n      inn\n      iian\n      kpp\n      ogrn\n      business {\n        main\n        aux\n        legalForm\n        startedAt\n      }\n      executive {\n        name\n      }\n      address {\n        raw\n        country\n        region\n        area\n        locality\n        street\n        house\n        housing\n        structure\n        flat\n        okato\n        settlement\n        postalCode\n        cityDistrict\n      }\n      actualAddress {\n        raw\n        country\n        region\n        area\n        locality\n        street\n        house\n        housing\n        structure\n        flat\n        okato\n        settlement\n        postalCode\n        cityDistrict\n      }\n      passport {\n        scans {\n          id\n          url\n          filename\n        }\n        id\n      }\n      borrower {\n        publicEmail\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '78bc5e5e51d6d79188228ba651d0047d';
export default node;
