import React from "react"
import { graphql } from "react-relay"

import { QueryRenderer } from "src/components"
import { RenderedTable } from "src/components/organisms/PortfolioTable/Tables/InvestmentPaymentGraph"
import {
  isForeignInvestor,
  isRussianEntrepreneur,
  isRussianInvestor,
  isJuristicBorrower,
} from "src/utils"

const queries = {
  investment: graphql`
    fragment InvestmentPaymentGraphFragment on InvestmentEdge {
      node {
        id
        amount
        order {
          id
          status
          application {
            data
          }
          chain {
            id
            gatheredAmount
          }
          paymentScheduleList {
            profile {
              id
            }
            info {
              state
            }
            items {
              date
              payDate
              loan
              state
              total
              total_gross
              interest
              interest_fee
              personal_tax
            }
          }
        }
      }
    }
  `,
  individualQuery: graphql`
    query InvestmentPaymentGraphIndividualQuery(
      $profileId: ID!
      $filter: ProfileInvestmentsFilter
    ) {
      node(id: $profileId) {
        ... on IndividualProfile {
          investor {
            investments(filter: $filter) {
              edges {
                ...InvestmentPaymentGraphFragment @relay(mask: false)
              }
            }
          }
        }
      }
    }
  `,
  entrepreneurQuery: graphql`
    query InvestmentPaymentGraphEntrepreneurQuery(
      $profileId: ID!
      $filter: ProfileInvestmentsFilter
    ) {
      node(id: $profileId) {
        ... on EntrepreneurProfile {
          investor {
            investments(filter: $filter) {
              edges {
                ...InvestmentPaymentGraphFragment @relay(mask: false)
              }
            }
          }
        }
      }
    }
  `,
  foreignQuery: graphql`
    query InvestmentPaymentGraphForeignQuery(
      $profileId: ID!
      $filter: ProfileInvestmentsFilter
    ) {
      node(id: $profileId) {
        ... on ForeignIndividualProfile {
          investor {
            investments(filter: $filter) {
              edges {
                ...InvestmentPaymentGraphFragment @relay(mask: false)
              }
            }
          }
        }
      }
    }
  `,
  legalEntityQuery: graphql`
    query InvestmentPaymentGraphLegalEntityQuery(
      $profileId: ID!
      $filter: ProfileInvestmentsFilter
    ) {
      node(id: $profileId) {
        ... on LegalEntityProfile {
          investor {
            investments(filter: $filter) {
              edges {
                ...InvestmentPaymentGraphFragment @relay(mask: false)
              }
            }
          }
        }
      }
    }
  `,
}

const getQuery = (profile) => {
  if (isRussianInvestor(profile)) {
    return queries.individualQuery
  }

  if (isRussianEntrepreneur(profile)) {
    return queries.entrepreneurQuery
  }

  if (isForeignInvestor(profile)) {
    return queries.foreignQuery
  }

  if (isJuristicBorrower(profile)) {
    return queries.legalEntityQuery
  }

  return null
}

const Container = (Render, profile) => (mainProps) => {
  const query = getQuery(profile)

  if (!query) {
    return null
  }

  return (
    <QueryRenderer
      {...mainProps}
      query={query}
      render={(props) => <Render {...props} />}
      renderNull={() => <RenderedTable isFetching={true} />}
      variables={{
        filter: { status: ["COMPLETE"] },
        profileId: profile.id,
      }}
    />
  )
}

export default Container
