import React from "react"

import { Translate, MiniLoader } from "src/components"

import { MobileInformationCompany, MobileStatusBar } from "../Elements"

import {
  MobileItemContainer as Container,
  MobileHeaderContainer,
  HeaderWrap,
  MobileInformationContainer,
  MobileInformationItem,
  MobileInformationName,
  MobileInformationValue,
  MobileGoalItem,
  MobileGoalValue,
  MobileGoalBar,
  GraphButton,
  CardCover,
  CardButtonsWrapper,
  CardButton,
} from "./styles"

import { MobileGraph } from "./icons"

function Head() {
  return (
    <HeaderWrap>
      <MobileHeaderContainer>
        <MobileInformationCompany name="_" />
      </MobileHeaderContainer>
    </HeaderWrap>
  )
}

function Info() {
  return (
    <CardCover>
      <MiniLoader changed fontSize="8px" margin="auto" />
    </CardCover>
  )
}

function Body(props) {
  const { isCession } = props

  return (
    <MobileInformationContainer>
      <MobileInformationItem>
        <MobileInformationName>№ заявки</MobileInformationName>
        <MobileInformationValue>_</MobileInformationValue>
      </MobileInformationItem>
      <MobileInformationItem>
        <MobileInformationName>
          <Translate i18n={"models:loan.rating"} />
        </MobileInformationName>
        <MobileInformationValue>_</MobileInformationValue>
      </MobileInformationItem>

      {!isCession ? (
        <>
          <MobileInformationItem>
            <MobileInformationName>
              <Translate i18n={"models:loan.rate"} />
            </MobileInformationName>
            <MobileInformationValue>0%</MobileInformationValue>
          </MobileInformationItem>
          <MobileInformationItem>
            <MobileInformationName>
              <Translate i18n={"models:loan.duration"} />
            </MobileInformationName>
            <MobileInformationValue>
              0 <Translate i18n={"models:loan.month"} />
            </MobileInformationValue>
          </MobileInformationItem>
        </>
      ) : (
        <>
          <MobileInformationItem>
            <MobileInformationName>Цена</MobileInformationName>
            <MobileInformationValue>0%</MobileInformationValue>
          </MobileInformationItem>
          <MobileInformationItem>
            <MobileInformationName>Факт до-ть</MobileInformationName>
            <MobileInformationValue>0%</MobileInformationValue>
          </MobileInformationItem>
          <MobileInformationItem>
            <MobileInformationName>Объем</MobileInformationName>
            <MobileInformationValue>0</MobileInformationValue>
          </MobileInformationItem>
        </>
      )}

      <MobileInformationItem>
        <MobileInformationName>Погашение ОД</MobileInformationName>
        <MobileInformationValue>
          <GraphButton>{MobileGraph}</GraphButton>В конце
        </MobileInformationValue>
      </MobileInformationItem>

      {!isCession ? (
        <>
          <MobileInformationItem>
            <MobileInformationName>Сбор до</MobileInformationName>
            <MobileInformationValue>_</MobileInformationValue>
          </MobileInformationItem>

          <MobileGoalItem>
            <MobileGoalValue>0М из 0М</MobileGoalValue>
            <MobileGoalBar>
              <MobileStatusBar value={0} res={true} />
            </MobileGoalBar>
          </MobileGoalItem>
        </>
      ) : (
        <>
          <MobileInformationItem>
            <MobileInformationName>Погашение займа</MobileInformationName>
            <MobileInformationValue>_</MobileInformationValue>
          </MobileInformationItem>
        </>
      )}
    </MobileInformationContainer>
  )
}

function FooterAction(props) {
  const { isCession } = props

  return (
    <CardButtonsWrapper>
      <CardButton compact>
        <Translate i18n={"models:loan.buttons.detailed"} />
      </CardButton>
      <CardButton compact primary>
        <Translate
          i18n={!isCession ? "models:loan.buttons.Invest" : "Купить"}
        />
      </CardButton>
    </CardButtonsWrapper>
  )
}

export default {
  Container,
  Head,
  Info,
  Body,
  FooterAction,
}
