/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type KYCDocumentTypeEnum = "DRIVERS" | "ID_CARD" | "OTHER" | "PASSPORT" | "RESIDENCE_PERMIT" | "%future added value";
export type ProfileAccreditationStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type TerritoryResidenceDocumentTypeEnum = "PERMANENT_RESIDENCY" | "VISA" | "%future added value";
export type CreateForeignIndividualProfileInput = {
    attributes: ForeignIndividualProfileAttributesInput;
    shouldSkipValidation?: boolean | null;
};
export type ForeignIndividualProfileAttributesInput = {
    firstName: string;
    lastName: string;
    middleName?: string | null;
    documents?: Array<KYCDocumentInput> | null;
    selfie?: string | null;
    phone: string;
    tin?: string | null;
    hasUSACitizenship?: boolean | null;
    isRussiaTaxResident?: boolean | null;
    residenceDocument?: string | null;
    translatedDocuments?: Array<string> | null;
    territoryResidenceDocument?: TerritoryResidenceDocumentInput | null;
    migrationCard?: MigrationCardInput | null;
    registrationAddress?: AddressInput | null;
    taxResidencyApplication?: Array<string> | null;
    innDocument?: Array<string> | null;
    bic?: string | null;
    checkingAccount?: string | null;
};
export type KYCDocumentInput = {
    attachments: Array<string>;
    type?: KYCDocumentTypeEnum | null;
};
export type TerritoryResidenceDocumentInput = {
    attachments: Array<string>;
    type: TerritoryResidenceDocumentTypeEnum;
    series?: string | null;
    number: string;
    dateFrom: string;
    dateTo?: string | null;
};
export type MigrationCardInput = {
    attachments: Array<string>;
    number: string;
    dateFrom: string;
    dateTo?: string | null;
};
export type AddressInput = {
    country?: string | null;
    region?: string | null;
    area?: string | null;
    locality?: string | null;
    street?: string | null;
    house?: string | null;
    housing?: string | null;
    structure?: string | null;
    flat?: string | null;
    raw?: string | null;
    countryIsoCode?: string | null;
    okato?: string | null;
    settlement?: string | null;
    postalCode?: string | null;
    cityDistrict?: string | null;
    unreliable?: boolean | null;
};
export type CreateForeignIndividualProfileMutationVariables = {
    input: CreateForeignIndividualProfileInput;
};
export type CreateForeignIndividualProfileMutationResponse = {
    readonly createForeignIndividualProfile: {
        readonly profile: {
            readonly id: string;
            readonly __typename: string;
            readonly phone: string;
            readonly lastName: string;
            readonly firstName: string;
            readonly middleName: string | null;
            readonly accreditation: {
                readonly status: ProfileAccreditationStatus | null;
            };
        };
    };
};
export type CreateForeignIndividualProfileMutation = {
    readonly response: CreateForeignIndividualProfileMutationResponse;
    readonly variables: CreateForeignIndividualProfileMutationVariables;
};



/*
mutation CreateForeignIndividualProfileMutation(
  $input: CreateForeignIndividualProfileInput!
) {
  createForeignIndividualProfile(input: $input) {
    profile {
      id
      __typename
      phone
      lastName
      firstName
      middleName
      accreditation(role: INVESTOR) {
        status
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateForeignIndividualProfileInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateForeignIndividualProfilePayload",
    "kind": "LinkedField",
    "name": "createForeignIndividualProfile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ForeignIndividualProfile",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "middleName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "role",
                "value": "INVESTOR"
              }
            ],
            "concreteType": "Accreditation",
            "kind": "LinkedField",
            "name": "accreditation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": "accreditation(role:\"INVESTOR\")"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateForeignIndividualProfileMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateForeignIndividualProfileMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CreateForeignIndividualProfileMutation",
    "operationKind": "mutation",
    "text": "mutation CreateForeignIndividualProfileMutation(\n  $input: CreateForeignIndividualProfileInput!\n) {\n  createForeignIndividualProfile(input: $input) {\n    profile {\n      id\n      __typename\n      phone\n      lastName\n      firstName\n      middleName\n      accreditation(role: INVESTOR) {\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4bff27a07bf23d0d206f37f35a882d30';
export default node;
