/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrderPaymentStatusEnum = "BEFOREHAND" | "DELAYED" | "PAID" | "%future added value";
export type OrderStatusEnum = "APPROVED" | "COMPLETE" | "CONFIRMED" | "DEAD" | "DECLINED" | "DEFAULT" | "INITIAL" | "PENDING" | "SUCCEEDED" | "TRANSFER" | "%future added value";
export type ProvisionKindEnum = "ESTATE" | "GUARANTOR" | "OTHER" | "VEHICLE" | "%future added value";
export type MarketCompletedOrdersHugeList_root = {
    readonly orders: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly __typename: string;
                readonly createdAt: string;
                readonly succeededAt: string | null;
                readonly status: OrderStatusEnum | null;
                readonly statusFrom: string | null;
                readonly tags: ReadonlyArray<{
                    readonly name: string;
                }> | null;
                readonly backgroundImage: {
                    readonly url: string;
                } | null;
                readonly offer: {
                    readonly id: string;
                    readonly data: string;
                } | null;
                readonly application: {
                    readonly shortTitle: string;
                    readonly longTitle: string;
                    readonly externalMedium: ReadonlyArray<{
                        readonly video: {
                            readonly url: string;
                        };
                    }>;
                    readonly id: string;
                    readonly data: string;
                } | null;
                readonly profile: {
                    readonly id: string;
                    readonly name: string;
                    readonly creditRating?: {
                        readonly rating: string;
                    } | null;
                    readonly _avatar?: {
                        readonly url: string;
                    } | null;
                    readonly borrower?: {
                        readonly ticker: string | null;
                    } | null;
                };
                readonly completedAt: string | null;
                readonly fee: number | null;
                readonly chain: {
                    readonly id: string;
                    readonly gatheredAmount: number;
                    readonly investorsCount: number;
                } | null;
                readonly creditRating: {
                    readonly rating: string;
                } | null;
                readonly paymentSchedule: {
                    readonly status: OrderPaymentStatusEnum | null;
                };
                readonly cession: {
                    readonly due: string;
                    readonly amount: number;
                    readonly isActive: boolean;
                    readonly interestRate: number;
                    readonly borrowerName: string;
                    readonly originalChainId: string;
                    readonly avatar: {
                        readonly url: string;
                    } | null;
                    readonly borrowerRating: {
                        readonly rating: string;
                        readonly ratedAt: string;
                    };
                } | null;
                readonly karma: {
                    readonly conclusion: string | null;
                    readonly isInvestor: boolean;
                };
                readonly viewer: {
                    readonly investedAmount: number;
                };
                readonly provision: {
                    readonly items: ReadonlyArray<{
                        readonly kind: ProvisionKindEnum;
                    }>;
                } | null;
            };
        }>;
    };
    readonly " $refType": "MarketCompletedOrdersHugeList_root";
};
export type MarketCompletedOrdersHugeList_root$data = MarketCompletedOrdersHugeList_root;
export type MarketCompletedOrdersHugeList_root$key = {
    readonly " $data"?: MarketCompletedOrdersHugeList_root$data;
    readonly " $fragmentRefs": FragmentRefs<"MarketCompletedOrdersHugeList_root">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "data",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rating",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "CreditRating",
  "kind": "LinkedField",
  "name": "creditRating",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "pageSize",
      "type": "Int"
    },
    {
      "kind": "RootArgument",
      "name": "after",
      "type": "Cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": "after",
        "direction": "forward",
        "path": [
          "orders"
        ]
      }
    ]
  },
  "name": "MarketCompletedOrdersHugeList_root",
  "selections": [
    {
      "alias": "orders",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "cession": false,
            "status": [
              "SUCCEEDED"
            ]
          }
        }
      ],
      "concreteType": "OrderConnection",
      "kind": "LinkedField",
      "name": "__ManageOrdersCompletedRelay_orders_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Order",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "succeededAt",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "statusFrom",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderTag",
                  "kind": "LinkedField",
                  "name": "tags",
                  "plural": true,
                  "selections": [
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Attachment",
                  "kind": "LinkedField",
                  "name": "backgroundImage",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderOffer",
                  "kind": "LinkedField",
                  "name": "offer",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderApplication",
                  "kind": "LinkedField",
                  "name": "application",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "shortTitle",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "longTitle",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ExternalMedia",
                      "kind": "LinkedField",
                      "name": "externalMedium",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ExternalMediaVideo",
                          "kind": "LinkedField",
                          "name": "video",
                          "plural": false,
                          "selections": (v3/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "profile",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v6/*: any*/)
                      ],
                      "type": "EntrepreneurProfile"
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v6/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Avatar",
                          "kind": "LinkedField",
                          "name": "_avatar",
                          "plural": false,
                          "selections": (v3/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Borrower",
                          "kind": "LinkedField",
                          "name": "borrower",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "ticker",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "LegalEntityProfile"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "completedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fee",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderChainInfo",
                  "kind": "LinkedField",
                  "name": "chain",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "gatheredAmount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "investorsCount",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PaymentScheduleEdges",
                  "kind": "LinkedField",
                  "name": "paymentSchedule",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderCession",
                  "kind": "LinkedField",
                  "name": "cession",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "due",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "amount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isActive",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "interestRate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "borrowerName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "originalChainId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Attachment",
                      "kind": "LinkedField",
                      "name": "avatar",
                      "plural": false,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CreditRating",
                      "kind": "LinkedField",
                      "name": "borrowerRating",
                      "plural": false,
                      "selections": [
                        (v5/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "ratedAt",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderKarma",
                  "kind": "LinkedField",
                  "name": "karma",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "conclusion",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isInvestor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderViewer",
                  "kind": "LinkedField",
                  "name": "viewer",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "investedAmount",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderProvision",
                  "kind": "LinkedField",
                  "name": "provision",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "items",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "kind",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "__ManageOrdersCompletedRelay_orders_connection(filter:{\"cession\":false,\"status\":[\"SUCCEEDED\"]})"
    }
  ],
  "type": "Query"
};
})();
(node as any).hash = '0063174152b4e1925a064d42a68b17e2';
export default node;
