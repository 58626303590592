import React from "react"
import { graphql, createPaginationContainer } from "react-relay"

import { QueryRenderer } from "src/components"
import { RenderedTable } from "src/components/organisms/PortfolioTable/Tables/InvestmentActiveTable"
import {
  isForeignInvestor,
  isRussianEntrepreneur,
  isRussianInvestor,
  isJuristicBorrower,
} from "src/utils"

import { getInvestmentConnectionConfig, InvestorRenderComponent } from "./utils"
import { VISIBLE_ORDER } from "./constants"

const queries = {
  investment: graphql`
    fragment InvestmentActiveOrdersListFragment on InvestmentEdge {
      node {
        id
        amount
        profile {
          id
        }
        order {
          id
          status
          confirmedAt
          offer {
            data
            receivedAt
            approvedAt
          }
          application {
            data
            shortTitle
            longTitle
          }
          chain {
            id
            gatheredAmount
          }
          profile {
            ... on UserProfile {
              id
              ... on LegalEntityProfile {
                name
                borrower {
                  ticker
                }
              }
            }
          }
        }
      }
    }
  `,
  individualQuery: graphql`
    query InvestmentActiveOrdersListIndividualQuery(
      $count: Int!
      $cursor: Cursor
      $profileId: ID!
      $filter: ProfileInvestmentsFilter
    ) {
      ...InvestmentActiveOrdersList_individual
      @arguments(
        count: $count
        cursor: $cursor
        profileId: $profileId
        filter: $filter
      )
    }
  `,
  entrepreneurQuery: graphql`
    query InvestmentActiveOrdersListEntrepreneurQuery(
      $count: Int!
      $cursor: Cursor
      $profileId: ID!
      $filter: ProfileInvestmentsFilter
    ) {
      ...InvestmentActiveOrdersList_entrepreneur
      @arguments(
        count: $count
        cursor: $cursor
        profileId: $profileId
        filter: $filter
      )
    }
  `,
  foreignQuery: graphql`
    query InvestmentActiveOrdersListForeignQuery(
      $count: Int!
      $cursor: Cursor
      $profileId: ID!
      $filter: ProfileInvestmentsFilter
    ) {
      ...InvestmentActiveOrdersList_foreign
      @arguments(
        count: $count
        cursor: $cursor
        profileId: $profileId
        filter: $filter
      )
    }
  `,
  legalEntity: graphql`
    query InvestmentActiveOrdersListLegalEntityQuery(
      $count: Int!
      $cursor: Cursor
      $profileId: ID!
      $filter: ProfileInvestmentsFilter
    ) {
      ...InvestmentActiveOrdersList_legalEntity
      @arguments(
        count: $count
        cursor: $cursor
        profileId: $profileId
        filter: $filter
      )
    }
  `,
}

const IndividualPaginatedOrderTable = createPaginationContainer(
  InvestorRenderComponent,
  {
    individual: graphql`
      fragment InvestmentActiveOrdersList_individual on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "Cursor" }
        profileId: { type: "ID!" }
        filter: { type: "ProfileInvestmentsFilter" }
      ) {
        node(id: $profileId) {
          ... on IndividualProfile {
            investor {
              investments(first: $count, after: $cursor, filter: $filter)
              @connection(key: "ActiveInvestments_investments") {
                edges {
                  ...InvestmentActiveOrdersListFragment @relay(mask: false)
                }
              }
            }
          }
        }
      }
    `,
  },
  getInvestmentConnectionConfig(queries.individualQuery, "individual"),
)

const EntrepreneurPaginatedOrderTable = createPaginationContainer(
  InvestorRenderComponent,
  {
    entrepreneur: graphql`
      fragment InvestmentActiveOrdersList_entrepreneur on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "Cursor" }
        profileId: { type: "ID!" }
        filter: { type: "ProfileInvestmentsFilter" }
      ) {
        node(id: $profileId) {
          ... on EntrepreneurProfile {
            investor {
              investments(first: $count, after: $cursor, filter: $filter)
              @connection(key: "ActiveInvestments_investments") {
                edges {
                  ...InvestmentActiveOrdersListFragment @relay(mask: false)
                }
              }
            }
          }
        }
      }
    `,
  },
  getInvestmentConnectionConfig(queries.entrepreneurQuery, "entrepreneur"),
)

const ForeignPaginatedOrderTable = createPaginationContainer(
  InvestorRenderComponent,
  {
    foreign: graphql`
      fragment InvestmentActiveOrdersList_foreign on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "Cursor" }
        profileId: { type: "ID!" }
        filter: { type: "ProfileInvestmentsFilter" }
      ) {
        node(id: $profileId) {
          ... on ForeignIndividualProfile {
            investor {
              investments(first: $count, after: $cursor, filter: $filter)
              @connection(key: "ActiveInvestments_investments") {
                edges {
                  ...InvestmentActiveOrdersListFragment @relay(mask: false)
                }
              }
            }
          }
        }
      }
    `,
  },
  getInvestmentConnectionConfig(queries.foreignQuery, "foreign"),
)

const LegalEntityPaginatedOrderTable = createPaginationContainer(
  InvestorRenderComponent,
  {
    legalEntity: graphql`
      fragment InvestmentActiveOrdersList_legalEntity on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "Cursor" }
        profileId: { type: "ID!" }
        filter: { type: "ProfileInvestmentsFilter" }
      ) {
        node(id: $profileId) {
          ... on LegalEntityProfile {
            investor {
              investments(first: $count, after: $cursor, filter: $filter)
              @connection(key: "ActiveInvestments_investments") {
                edges {
                  ...InvestmentActiveOrdersListFragment @relay(mask: false)
                }
              }
            }
          }
        }
      }
    `,
  },
  getInvestmentConnectionConfig(queries.legalEntity, "legalEntity"),
)

const getContainer = (render, profile) => {
  if (isRussianInvestor(profile)) {
    return [
      (props) => (
        <IndividualPaginatedOrderTable
          individual={props}
          render={render}
          profileType="individual"
        />
      ),
      queries.individualQuery,
    ]
  }

  if (isRussianEntrepreneur(profile)) {
    return [
      (props) => (
        <EntrepreneurPaginatedOrderTable
          entrepreneur={props}
          render={render}
          profileType="entrepreneur"
        />
      ),
      queries.entrepreneurQuery,
    ]
  }

  if (isForeignInvestor(profile)) {
    return [
      (props) => (
        <ForeignPaginatedOrderTable
          foreign={props}
          render={render}
          profileType="foreign"
        />
      ),
      queries.foreignQuery,
    ]
  }

  if (isJuristicBorrower(profile)) {
    return [
      (props) => (
        <LegalEntityPaginatedOrderTable
          legalEntity={props}
          render={render}
          profileType="legalEntity"
        />
      ),
      queries.legalEntity,
    ]
  }

  return []
}

const Render = (render, profile) => (mainProps) => {
  const [Container, query] = getContainer(render, profile)

  if (!Container) {
    return null
  }

  return (
    <QueryRenderer
      {...mainProps}
      query={query}
      render={Container}
      renderNull={() => <RenderedTable isFetching={true} load={{}} />}
      variables={{
        profileId: profile.id,
        filter: {
          status: ["CONFIRMED", "TRANSFER"],
          cession: false,
        },
        count: VISIBLE_ORDER,
      }}
    />
  )
}

export default Render
