import React, { useState } from "react"
import useCollapse from "react-collapsed"

import {
  HeadTh,
  HeadTr,
  TableHead,
  Table,
  Title,
  Description,
  TableRow,
  TitleContainer,
  CollapseButton,
  ArrowIcon,
  CollapsedContent,
} from "./styles"

import { MOCK_ORDERS } from "../../../OrdersList/Lists/Market/constants"

const collapseStyles = {
  transitionDuration: ".3s",
  transitionTimingFunction: "cubic-bezier(0.250, 0.460, 0.450, 0.940)",
}

export default function (props) {
  const {
    title,
    descr,
    columns,
    items,
    isFetching,
    condition,
    additionalColumns,
    isExpanded = true,
    setExpanded,
  } = props

  const [isHover, setIsHover] = useState(null)
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
    expandStyles: collapseStyles,
    collapseStyles,
  })

  return (
    <div>
      {!!title && (
        <TitleContainer isExpanded={isExpanded}>
          <Title>{title}</Title>

          {!!setExpanded && (
            <CollapseButton
              {...getToggleProps({
                onClick: () => setExpanded((prevExpanded) => !prevExpanded),
              })}
              disabled={isFetching}
            >
              <ArrowIcon />
            </CollapseButton>
          )}
        </TitleContainer>
      )}

      <CollapsedContent {...getCollapseProps()}>
        {descr && <Description>{descr}</Description>}

        <Table cellPadding="5">
          <TableHead>
            <HeadTr>
              {columns.map((column, i) => (
                <HeadTh key={i}>{column.title}</HeadTh>
              ))}
            </HeadTr>
          </TableHead>
          <tbody>
            {!isFetching
              && items.map((item, i) => (
                <TableRow
                  key={i}
                  onMouseLeave={() => setIsHover(null)}
                  onMouseEnter={() => setIsHover(i)}
                >
                  {typeof condition === "function" && condition(item)
                    ? additionalColumns.map((column, j) => column.render(j, item, isHover === i))
                    : columns.map((column, j) => column.render(j, item, isHover === i))}
                </TableRow>
              ))}

            {isFetching
              && [...Array(MOCK_ORDERS)].map((item, i) => (
                <TableRow key={i}>
                  {columns.map((column, j) => column.placeholder(j))}
                </TableRow>
              ))}
          </tbody>
        </Table>
      </CollapsedContent>
    </div>
  )
}
