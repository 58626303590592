/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type KYCDocumentTypeEnum = "DRIVERS" | "ID_CARD" | "OTHER" | "PASSPORT" | "RESIDENCE_PERMIT" | "%future added value";
export type TerritoryResidenceDocumentTypeEnum = "PERMANENT_RESIDENCY" | "VISA" | "%future added value";
export type UpdateForeignIndividualProfileInput = {
    profileId: string;
    attributes: ForeignIndividualProfileAttributesInput;
    shouldSkipValidation?: boolean | null;
};
export type ForeignIndividualProfileAttributesInput = {
    firstName: string;
    lastName: string;
    middleName?: string | null;
    documents?: Array<KYCDocumentInput> | null;
    selfie?: string | null;
    phone: string;
    tin?: string | null;
    hasUSACitizenship?: boolean | null;
    isRussiaTaxResident?: boolean | null;
    residenceDocument?: string | null;
    translatedDocuments?: Array<string> | null;
    territoryResidenceDocument?: TerritoryResidenceDocumentInput | null;
    migrationCard?: MigrationCardInput | null;
    registrationAddress?: AddressInput | null;
    taxResidencyApplication?: Array<string> | null;
    innDocument?: Array<string> | null;
    bic?: string | null;
    checkingAccount?: string | null;
};
export type KYCDocumentInput = {
    attachments: Array<string>;
    type?: KYCDocumentTypeEnum | null;
};
export type TerritoryResidenceDocumentInput = {
    attachments: Array<string>;
    type: TerritoryResidenceDocumentTypeEnum;
    series?: string | null;
    number: string;
    dateFrom: string;
    dateTo?: string | null;
};
export type MigrationCardInput = {
    attachments: Array<string>;
    number: string;
    dateFrom: string;
    dateTo?: string | null;
};
export type AddressInput = {
    country?: string | null;
    region?: string | null;
    area?: string | null;
    locality?: string | null;
    street?: string | null;
    house?: string | null;
    housing?: string | null;
    structure?: string | null;
    flat?: string | null;
    raw?: string | null;
    countryIsoCode?: string | null;
    okato?: string | null;
    settlement?: string | null;
    postalCode?: string | null;
    cityDistrict?: string | null;
    unreliable?: boolean | null;
};
export type UpdateForeignIndividualProfileMutationVariables = {
    input: UpdateForeignIndividualProfileInput;
};
export type UpdateForeignIndividualProfileMutationResponse = {
    readonly updateForeignIndividualProfile: {
        readonly profile: {
            readonly id: string;
            readonly __typename: string;
            readonly " $fragmentRefs": FragmentRefs<"AccreditationInfoFragment_foreignProfile">;
        };
    };
};
export type UpdateForeignIndividualProfileMutation = {
    readonly response: UpdateForeignIndividualProfileMutationResponse;
    readonly variables: UpdateForeignIndividualProfileMutationVariables;
};



/*
mutation UpdateForeignIndividualProfileMutation(
  $input: UpdateForeignIndividualProfileInput!
) {
  updateForeignIndividualProfile(input: $input) {
    profile {
      id
      __typename
      ...AccreditationInfoFragment_foreignProfile
    }
  }
}

fragment AccreditationInfoFragment_foreignProfile on ForeignIndividualProfile {
  id
  __typename
  firstName
  lastName
  phone
  documents {
    type
    attachments {
      id
      url
      filename
    }
  }
  translatedDocuments {
    id
    url
    filename
  }
  selfie {
    id
    url
    filename
  }
  accreditation(role: INVESTOR) {
    status
    declineReason
  }
  tin
  isRussiaTaxResident
  hasUSACitizenship
  taxResidencyApplication {
    id
    filename
    url
    size
    signature {
      url
    }
  }
  migrationCard {
    number
    dateFrom
    dateTo
    attachments {
      id
      filename
      url
      size
      signature {
        url
      }
    }
  }
  innDocument {
    id
    url
    filename
  }
  residenceDocument {
    id
    url
    filename
  }
  territoryResidenceDocument {
    type
    series
    number
    dateFrom
    dateTo
    attachments {
      id
      filename
      url
      size
      signature {
        url
      }
    }
  }
  registrationAddress {
    country
    region
    area
    locality
    street
    house
    housing
    structure
    flat
    okato
    settlement
    postalCode
    cityDistrict
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateForeignIndividualProfileInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filename",
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = [
  (v2/*: any*/),
  (v6/*: any*/),
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "size",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "DocumentSignature",
    "kind": "LinkedField",
    "name": "signature",
    "plural": false,
    "selections": [
      (v5/*: any*/)
    ],
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateFrom",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateTo",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "attachments",
  "plural": true,
  "selections": (v8/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateForeignIndividualProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateForeignIndividualProfilePayload",
        "kind": "LinkedField",
        "name": "updateForeignIndividualProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ForeignIndividualProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AccreditationInfoFragment_foreignProfile"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateForeignIndividualProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateForeignIndividualProfilePayload",
        "kind": "LinkedField",
        "name": "updateForeignIndividualProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ForeignIndividualProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "KYCDocument",
                "kind": "LinkedField",
                "name": "documents",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Attachment",
                    "kind": "LinkedField",
                    "name": "attachments",
                    "plural": true,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "translatedDocuments",
                "plural": true,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "selfie",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "role",
                    "value": "INVESTOR"
                  }
                ],
                "concreteType": "Accreditation",
                "kind": "LinkedField",
                "name": "accreditation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "declineReason",
                    "storageKey": null
                  }
                ],
                "storageKey": "accreditation(role:\"INVESTOR\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isRussiaTaxResident",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasUSACitizenship",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "taxResidencyApplication",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MigrationCard",
                "kind": "LinkedField",
                "name": "migrationCard",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "innDocument",
                "plural": true,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "residenceDocument",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TerritoryResidenceDocument",
                "kind": "LinkedField",
                "name": "territoryResidenceDocument",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "series",
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "registrationAddress",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "region",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "area",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "locality",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "street",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "house",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "housing",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "structure",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "flat",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "okato",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "settlement",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "postalCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cityDistrict",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "UpdateForeignIndividualProfileMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateForeignIndividualProfileMutation(\n  $input: UpdateForeignIndividualProfileInput!\n) {\n  updateForeignIndividualProfile(input: $input) {\n    profile {\n      id\n      __typename\n      ...AccreditationInfoFragment_foreignProfile\n    }\n  }\n}\n\nfragment AccreditationInfoFragment_foreignProfile on ForeignIndividualProfile {\n  id\n  __typename\n  firstName\n  lastName\n  phone\n  documents {\n    type\n    attachments {\n      id\n      url\n      filename\n    }\n  }\n  translatedDocuments {\n    id\n    url\n    filename\n  }\n  selfie {\n    id\n    url\n    filename\n  }\n  accreditation(role: INVESTOR) {\n    status\n    declineReason\n  }\n  tin\n  isRussiaTaxResident\n  hasUSACitizenship\n  taxResidencyApplication {\n    id\n    filename\n    url\n    size\n    signature {\n      url\n    }\n  }\n  migrationCard {\n    number\n    dateFrom\n    dateTo\n    attachments {\n      id\n      filename\n      url\n      size\n      signature {\n        url\n      }\n    }\n  }\n  innDocument {\n    id\n    url\n    filename\n  }\n  residenceDocument {\n    id\n    url\n    filename\n  }\n  territoryResidenceDocument {\n    type\n    series\n    number\n    dateFrom\n    dateTo\n    attachments {\n      id\n      filename\n      url\n      size\n      signature {\n        url\n      }\n    }\n  }\n  registrationAddress {\n    country\n    region\n    area\n    locality\n    street\n    house\n    housing\n    structure\n    flat\n    okato\n    settlement\n    postalCode\n    cityDistrict\n  }\n}\n"
  }
};
})();
(node as any).hash = '4dc1139d540ec6dbbe3018a4ba5b4ea4';
export default node;
