import is from "is_js"
import React from "react"

import { HOSTS_POOLS } from "src/constants"
import { RegisterUser } from "src/mutations"
import { getCid, getUtmContent } from "src/utils"

import {
  Box,
  Text,
  Select,
  Button,
  Translate,
  CheckBoxField,
  FloatingLabelInput,
} from "src/components"

import ComposedConsumer from "./composedConsumer"

import {
  EmailTooltip,
  errorForField,
  ConditionsAcceptedLabel,
  PersonalDataAcceptedLabel,
  ShouldSubscribeToMailingListLabel,
} from "./elements"

import onRegisterClick, { getDefaultLocale } from "./utils"

const CheckBoxSchema = [
  {
    name: "conditionsAccepted",
    label: (country) => <ConditionsAcceptedLabel country={country} />,
  },
  {
    name: "personalDataAccepted",
    label: (country) => <PersonalDataAcceptedLabel country={country} />,
  },
  {
    name: "shouldSubscribeToMailingList",
    label: () => <ShouldSubscribeToMailingListLabel />,
  },
]

const AcceptData = (props: any) => {
  const {
    // country,
    value,
    onChange,
  } = props

  // if (!country || country.length === 0) return null

  const country = "RU"

  return (
    <>
      {CheckBoxSchema.map(({ name, label }, index) => (
        <Box mt="20px" key={name + index}>
          <CheckBoxField
            fill="white"
            name={name}
            fontSize="14px"
            color="greyDarker"
            checked={value[name]}
            onChange={onChange(name)}
            label={label(country)}
          />
        </Box>
      ))}
    </>
  )
}

class Form extends React.Component<any, any> {
  state = {
    busy: false,
    email: "",
    country: "",
    conditionsAccepted: false,
    personalDataAccepted: false,
    shouldSubscribeToMailingList: false,
  }

  onError = (transaction) => {
    this.setState(
      () => ({ busy: false }),
      () => this.props.setErrors(transaction),
    )
  }

  onCompleted = () => {
    this.setState(
      () => ({ busy: false }),
      () => this.props.onCompleted({ email: this.state.email }),
    )
  }

  commit = () => {
    const variables = {
      input: {
        email: this.state.email.trim(),
        locale: getDefaultLocale(this.state.country),
        country: this.state.country,
        shouldSubscribeToMailingList: this.state.shouldSubscribeToMailingList,
        analytics: {
          cid: getCid(),
          utms: getUtmContent(),
          userAgent: window.navigator ? window.navigator.userAgent : null,
        },
        pools: [
          ...(HOSTS_POOLS[window.location.host] || []),
        ],
      },
    }

    const callbacks = {
      onError: this.onError,
      onCompleted: this.onCompleted,
    }

    RegisterUser.commit(this.props.environment, variables, null, callbacks)
  }

  onSubmit = (event) => {
    if (event) {
      event.preventDefault()
    }

    if (this.valid === false || this.state.busy === true) {
      return
    }

    this.setState(() => ({ busy: true }), this.commit)
  }

  onEmailChange = (event) => {
    const email = event.target.value
    this.setState(
      () => ({ email }),
      () => this.props.clearError("registerUser.email"),
    )
  }

  onSelectChange = (country) => {
    if (!country) return

    const { code } = country

    this.setState(
      () => ({ country: code }),
      () => this.props.clearError("registerUser.country"),
    )
  }

  onCheckBoxChange = (name) => (event) => {
    const { checked } = event.target
    this.setState(() => ({ [name]: checked }))
  }

  get valid() {
    return (
      is.email(this.state.email.trim())
      && is.truthy(
        this.state.conditionsAccepted && this.state.personalDataAccepted,
      )
      && this.state.country
      && this.state.country.length > 0
    )
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <Box mb="15px">
          <Text fontSize="24px">
            <Translate i18n="components:signup-form.title" />
          </Text>
        </Box>
        <FloatingLabelInput
          autoFocus
          errorColor="white"
          tooltip={EmailTooltip}
          value={this.state.email}
          onChange={this.onEmailChange}
          label="components:signup-form.email-label"
          error={errorForField(this.props.getError("registerUser.email"))}
        />
        <Select.redesigned onChange={this.onSelectChange} />
        <AcceptData
          country={this.state.country}
          value={this.state}
          onChange={this.onCheckBoxChange}
          error={errorForField(this.props.getError("registerUser.country"))}
        />
        <Box mt="20px">
          <Button
            fullWidth
            type="submit"
            onClick={onRegisterClick}
            disabled={!this.valid || this.state.busy}
          >
            <Translate i18n="signup-form.submit-button" ns="components" />
          </Button>
        </Box>
      </form>
    )
  }
}

const render = (props) => (
  <ComposedConsumer>
    {(renderProps) => {
      const { errors, locale, environment } = renderProps

      return (
        <Form
          {...props}
          {...errors}
          locale={locale}
          environment={environment}
        />
      )
    }}
  </ComposedConsumer>
)

export default render
