/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SexEnum = "FEMALE" | "MALE" | "%future added value";
export type EditTrustedProfile_profile = {
    readonly id: string;
    readonly __typename: string;
    readonly user: {
        readonly id: string;
        readonly email: string;
    };
    readonly name?: string;
    readonly phone?: string | null;
    readonly inn?: string | null;
    readonly kpp?: string | null;
    readonly ogrn?: string | null;
    readonly iian?: string | null;
    readonly borrower?: {
        readonly publicEmail: string | null;
    } | null;
    readonly address?: {
        readonly country: string | null;
        readonly region: string | null;
        readonly area: string | null;
        readonly locality: string | null;
        readonly street: string | null;
        readonly house: string | null;
        readonly housing: string | null;
        readonly structure: string | null;
        readonly flat: string | null;
        readonly raw: string | null;
        readonly countryIsoCode: string | null;
        readonly okato: string | null;
        readonly settlement: string | null;
        readonly postalCode: string | null;
        readonly cityDistrict: string | null;
    } | null;
    readonly actualAddress?: {
        readonly country: string | null;
        readonly region: string | null;
        readonly area: string | null;
        readonly locality: string | null;
        readonly street: string | null;
        readonly house: string | null;
        readonly housing: string | null;
        readonly structure: string | null;
        readonly flat: string | null;
        readonly raw: string | null;
        readonly countryIsoCode: string | null;
        readonly okato: string | null;
        readonly settlement: string | null;
        readonly postalCode: string | null;
        readonly cityDistrict: string | null;
    } | null;
    readonly business?: {
        readonly main: string | null;
        readonly aux: string | null;
        readonly legalForm: string | null;
        readonly startedAt: string | null;
    } | null;
    readonly passport?: {
        readonly scans: ReadonlyArray<{
            readonly id: string;
            readonly url: string;
            readonly filename: string;
        }>;
        readonly id?: string | null;
        readonly series?: string | null;
        readonly number?: string | null;
        readonly issuedAt?: string | null;
        readonly issuedBy?: {
            readonly code: string | null;
            readonly name: string | null;
        } | null;
        readonly postponeScans?: boolean | null;
    } | null;
    readonly executive?: {
        readonly name: string;
    } | null;
    readonly lastName?: string;
    readonly firstName?: string;
    readonly middleName?: string | null;
    readonly birthDate?: string | null;
    readonly birthPlace?: string | null;
    readonly isPublicOfficial?: boolean | null;
    readonly sex?: SexEnum | null;
    readonly isRussiaTaxResident?: boolean;
    readonly " $refType": "EditTrustedProfile_profile";
};
export type EditTrustedProfile_profile$data = EditTrustedProfile_profile;
export type EditTrustedProfile_profile$key = {
    readonly " $data"?: EditTrustedProfile_profile$data;
    readonly " $fragmentRefs": FragmentRefs<"EditTrustedProfile_profile">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inn",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ogrn",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "iian",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Borrower",
  "kind": "LinkedField",
  "name": "borrower",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicEmail",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "country",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "region",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "area",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "locality",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "street",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "house",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "housing",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "structure",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "flat",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "raw",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "countryIsoCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "okato",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "settlement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "postalCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "cityDistrict",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "actualAddress",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "scans",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filename",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditTrustedProfile_profile",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kpp",
          "storageKey": null
        },
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Business",
          "kind": "LinkedField",
          "name": "business",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "main",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "aux",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "legalForm",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startedAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Passport",
          "kind": "LinkedField",
          "name": "passport",
          "plural": false,
          "selections": [
            (v10/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "executive",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "LegalEntityProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "middleName",
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "birthDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "birthPlace",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isPublicOfficial",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isRussiaTaxResident",
          "storageKey": null
        },
        (v6/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Passport",
          "kind": "LinkedField",
          "name": "passport",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "series",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "issuedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PassportIssuer",
              "kind": "LinkedField",
              "name": "issuedBy",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postponeScans",
              "storageKey": null
            },
            (v10/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "EntrepreneurProfile"
    }
  ],
  "type": "UserProfile"
};
})();
(node as any).hash = '51681279f1b5b3185edd427fca8bf2a7';
export default node;
