import React from "react"

import { MIN_INVESTMENT_AMOUNT } from "src/constants/system"
import { getProfileDetailedBalance, getByCurrencyFromDetailedBalance } from "src/utils/profiles"

import { notifyGTM } from "src/utils/marketingEvents"

import { OrderType, AmountType } from "../types"

const minValueError = {
  keyword: "min",
  path: "investToOrder.amount",
}

export const calcMaxInvestmentAmount = (profile, availableOrderInvestmentAmount: number) => {
  if (!profile) return 0

  const detailedBalances = getProfileDetailedBalance(profile)
  const rubleBalance = getByCurrencyFromDetailedBalance(detailedBalances, "RUB")
  return availableOrderInvestmentAmount >= rubleBalance.available
    ? rubleBalance.available
    : availableOrderInvestmentAmount
}

export const sendGTMEvent = (order: OrderType) => {
  const { cession, application, id: orderId } = order

  notifyGTM({
    event: "checkoutFunnel2",
    eventCategory: "",
    extras: {
      ecommerce: {
        checkout: {
          actionField: { step: 2 },
          products: [
            {
              id: orderId,
              quantity: 1,
              name: application.shortTitle || application.data.companyName,
              category: cession && cession.isActive ? "Цессия" : "Первичная",
            },
          ],
        },
      },
    },
  })()
}

export const useOnSubmit = (props: AmountType) => React.useCallback(() => {
  const amountAsNumber = Number(
    props.amount.replace(",", "").replace(/\s/g, ""),
  )
  const maxInvestmentAmount = calcMaxInvestmentAmount(props.profile, props.availableAmount)
  const isInitialInvestment = (props.order.viewer.investedAmount > 0)

  if ((amountAsNumber < MIN_INVESTMENT_AMOUNT) && (!isInitialInvestment)) {
    props.setManualError(minValueError)
    return
  }

  if (amountAsNumber > maxInvestmentAmount) {
    return
  }

  sendGTMEvent(props.order)

  props.onComplete()
}, [props.amount])
