/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SexEnum = "FEMALE" | "MALE" | "%future added value";
export type UpdateIndividualProfileAsInvestorInput = {
    profileId: string;
    inn: string;
    iian: string;
    address: AddressInput;
    passport: PassportInput;
    birthDate: string;
    birthPlace: string;
    isPublicOfficial: boolean;
    isRussiaTaxResident: boolean;
    shouldSkipValidation?: boolean | null;
    sex?: SexEnum | null;
    bic?: string | null;
    checkingAccount?: string | null;
    supportingDocuments?: Array<string> | null;
};
export type AddressInput = {
    country?: string | null;
    region?: string | null;
    area?: string | null;
    locality?: string | null;
    street?: string | null;
    house?: string | null;
    housing?: string | null;
    structure?: string | null;
    flat?: string | null;
    raw?: string | null;
    countryIsoCode?: string | null;
    okato?: string | null;
    settlement?: string | null;
    postalCode?: string | null;
    cityDistrict?: string | null;
    unreliable?: boolean | null;
};
export type PassportInput = {
    series?: string | null;
    number?: string | null;
    issuedAt?: string | null;
    issuedBy?: PassportIssuerInput | null;
    scans: Array<string>;
    postponeScans?: boolean | null;
};
export type PassportIssuerInput = {
    code?: string | null;
    name?: string | null;
};
export type UpdateIndividualProfileAsInvestorMutationVariables = {
    input: UpdateIndividualProfileAsInvestorInput;
};
export type UpdateIndividualProfileAsInvestorMutationResponse = {
    readonly updateIndividualProfileAsInvestor: {
        readonly profile: {
            readonly id: string;
        };
    };
};
export type UpdateIndividualProfileAsInvestorMutation = {
    readonly response: UpdateIndividualProfileAsInvestorMutationResponse;
    readonly variables: UpdateIndividualProfileAsInvestorMutationVariables;
};



/*
mutation UpdateIndividualProfileAsInvestorMutation(
  $input: UpdateIndividualProfileAsInvestorInput!
) {
  updateIndividualProfileAsInvestor(input: $input) {
    profile {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateIndividualProfileAsInvestorInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateIndividualProfileAsInvestorPayload",
    "kind": "LinkedField",
    "name": "updateIndividualProfileAsInvestor",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "IndividualProfile",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateIndividualProfileAsInvestorMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateIndividualProfileAsInvestorMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "UpdateIndividualProfileAsInvestorMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateIndividualProfileAsInvestorMutation(\n  $input: UpdateIndividualProfileAsInvestorInput!\n) {\n  updateIndividualProfileAsInvestor(input: $input) {\n    profile {\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '92601d04adf79a9f23a5428e5ce5d37b';
export default node;
