/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type autoSuggestQueryVariables = {
    query: string;
};
export type autoSuggestQueryResponse = {
    readonly address: ReadonlyArray<{
        readonly value: string;
        readonly postalCode: string | null;
        readonly okato: string | null;
        readonly region: string | null;
        readonly regionType: string | null;
        readonly area: string | null;
        readonly areaType: string | null;
        readonly city: string | null;
        readonly cityType: string | null;
        readonly cityDistrict: string | null;
        readonly cityDistrictType: string | null;
        readonly settlement: string | null;
        readonly settlementType: string | null;
        readonly street: string | null;
        readonly streetType: string | null;
        readonly house: string | null;
        readonly houseType: string | null;
        readonly block: string | null;
        readonly blockType: string | null;
        readonly flat: string | null;
        readonly flatType: string | null;
        readonly postalBox: string | null;
    }>;
};
export type autoSuggestQuery = {
    readonly response: autoSuggestQueryResponse;
    readonly variables: autoSuggestQueryVariables;
};



/*
query autoSuggestQuery(
  $query: String!
) {
  address(query: $query) {
    value
    postalCode
    okato
    region
    regionType
    area
    areaType
    city
    cityType
    cityDistrict
    cityDistrictType
    settlement
    settlementType
    street
    streetType
    house
    houseType
    block
    blockType
    flat
    flatType
    postalBox
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "query",
        "variableName": "query"
      }
    ],
    "concreteType": "FoundAddressType",
    "kind": "LinkedField",
    "name": "address",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "okato",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "region",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "regionType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "area",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "areaType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "city",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cityType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cityDistrict",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cityDistrictType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "settlement",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "settlementType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "street",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "streetType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "house",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "houseType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "block",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blockType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "flat",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "flatType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalBox",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "autoSuggestQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "autoSuggestQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "autoSuggestQuery",
    "operationKind": "query",
    "text": "query autoSuggestQuery(\n  $query: String!\n) {\n  address(query: $query) {\n    value\n    postalCode\n    okato\n    region\n    regionType\n    area\n    areaType\n    city\n    cityType\n    cityDistrict\n    cityDistrictType\n    settlement\n    settlementType\n    street\n    streetType\n    house\n    houseType\n    block\n    blockType\n    flat\n    flatType\n    postalBox\n  }\n}\n"
  }
};
})();
(node as any).hash = '9c499cf90f7276eac3f259ff77e6258b';
export default node;
