import { graphql } from "relay-runtime"

const ProfileFormFragment = graphql`
  fragment ProfileFormFragment_profile on UserProfile {
    id
    __typename
    bankAccounts {
      id
      status
      checkingAccount
      correspondentAccount
      bank {
        bic
        name
      }
    }
    ... on IndividualProfile {
      name
      phone
      isRussiaTaxResident
      sex
      name
      inn
      ifns
      iian
      birthDate
      birthPlace
      employment
      isPublicOfficial
      profileTransactions: transactions {
        count
      }
      accreditation(role: INVESTOR) {
        status
        declineReason
      }
      registrationAddress {
        ... on Address {
          country
          region
          area
          locality
          street
          house
          housing
          structure
          flat
          okato
          settlement
          postalCode
          cityDistrict
        }
      }
      passport {
        ... on Passport {
          series
          number
          issuedAt
          scans {
            id
            url
            filename
          }
          issuedBy {
            ... on PassportIssuer {
              code
              name
            }
          }
        }
      }
      supportingDocuments {
        ... on SupportingDocument {
          id
          attachment {
            id
            url
            size
            width
            height
            format
            filename
          }
          status
        }
      }
    }
    ... on EntrepreneurProfile {
      name
      phone
      isRussiaTaxResident
      sex
      name
      inn
      psrnie
      ifns
      iian
      birthDate
      birthPlace
      isPublicOfficial
      registrationAddress {
        ... on Address {
          country
          region
          area
          locality
          street
          house
          housing
          structure
          flat
          okato
          settlement
          postalCode
          cityDistrict
        }
      }
      passport {
        ... on Passport {
          series
          number
          issuedAt
          scans {
            id
            url
            filename
          }
          issuedBy {
            ... on PassportIssuer {
              code
              name
            }
          }
        }
      }
      profileTransactions: transactions {
        count
      }
      accreditation(role: INVESTOR) {
        status
        declineReason
      }
    }
    ... on ForeignIndividualProfile {
      name
      firstName
      lastName
      foreignPhone: phone
      tin
      profileTransactions: transactions {
        count
      }
      selfie {
        id
        url
        filename
        signature {
          url
        }
      }
      accreditation(role: INVESTOR) {
        status
        declineReason
      }
      isRussiaTaxResident
      taxResidencyApplication {
        id
        filename
        url
        size
        signature {
          url
        }
      }
      migrationCard {
        number
        dateFrom
        dateTo
        attachments {
          id
          filename
          url
          size
          signature {
            url
          }
        }
      }
      innDocument {
        id
        url
        filename
      }
      residenceDocument {
        id
        filename
        url
        size
        signature {
          url
        }
      }
      territoryResidenceDocument {
        type
        series
        number
        dateFrom
        dateTo
        attachments {
          id
          filename
          url
          size
          signature {
            url
          }
        }
      }
      documents {
        type
        attachments {
          id
          url
          size
          filename
          access
          format
          signature {
            url
          }
        }
      }
      translatedDocuments {
        id
        url
        filename
      }
      registrationAddress {
        ... on Address {
          country
          region
          area
          locality
          street
          house
          housing
          structure
          flat
          okato
          settlement
          postalCode
          cityDistrict
        }
      }
    }
    ... on LegalEntityProfile {
      name
      inn
      phone
      iian
      inn
      kpp
      ogrn
      name
      borrower {
        publicEmail
      }
      profileTransactions: transactions {
        count
      }
      accreditation(role: BORROWER) {
        status
        declineReason
      }
      executive {
        name
      }
      business {
        ... on Business {
          main
          aux
          legalForm
          startedAt
        }
      }
      address {
        ... on Address {
          raw
          country
          region
          area
          locality
          street
          house
          housing
          structure
          flat
          okato
          settlement
          postalCode
          cityDistrict
        }
      }
      actualAddress {
        ... on Address {
          raw
          country
          region
          area
          locality
          street
          house
          housing
          structure
          flat
          okato
          settlement
          postalCode
          cityDistrict
        }
      }
      passport {
        ... on Passport {
          scans {
            id
            url
            filename
          }
        }
      }
    }
  }
`

export default ProfileFormFragment
