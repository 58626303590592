import React, { useState, useContext, useCallback } from "react"
import { Spring } from "react-spring/renderprops"
import { graphql } from "relay-runtime"
import { useSubscription } from "src/hooks"

import { isNotQualifiedInvestor } from "src/utils"
import { QualifiedInfoModal } from "src/components"
import { CurrentProfileContext } from "src/context"

import {
  COMPLETE,
  SUCCEEDED,
  CONFIRMED,
} from "../../ProposalHeader/constants"

import {
  InfoBlock, Timer, Button, StatusBar,
} from "../Elements"

import {
  getClosingTime,
  divideNumber,
  getStatusBarValue,
  getStatusBarMinValue,
  getProposalGoal,
  getFinishPaymentDate,
  getPaymentAmount,
  getLoanDate,
} from "./utils"

import {
  getIsDisabledButton,
} from "./getIsDisabledButton"

import {
  Container,
  Divider,
  DesktopContainer,
  MobileContainer,
} from "./styles"

import { InvestorProposalSpecificationForGatheredAmountSubscription as SubscriptionOperation }
  from "./__generated__/InvestorProposalSpecificationForGatheredAmountSubscription.graphql"

const subscription = graphql`
  subscription InvestorProposalSpecificationForGatheredAmountSubscription(
    $input: GatheredAmountSubscriptionInput!
  ) {
    gatheredAmount(input: $input) {
      order {
        status
        chain {
          gatheredAmount
          investorsCount
        }
        viewer {
          investedAmount
        }
      }
    }
  }
`

function InvestorProposalSpecification(props) {
  const [data, setData] = useState(props.data)
  const {
    chain, viewer, status, application,
  } = data

  const { profile = {} } = useContext(CurrentProfileContext) as any
  const { id: profileId } = profile

  const [isOpened, setOpened] = useState(false)

  const InvestmentButton = React.useMemo(
    () => (btnProps) => (
      <Button {...btnProps} descr="Мин сумма 10 000 ₽">
        {viewer.investedAmount > 0 && "инвестировать еще"}
        {viewer.investedAmount === 0 && "инвестировать"}
      </Button>
    ),
    [viewer.investedAmount],
  )

  const isDisabled = React.useMemo(
    () => getIsDisabledButton(profile, data),
    [data, profile],
  )

  const goToInvest = useCallback(() => {
    if (isNotQualifiedInvestor(profile)) {
      setOpened(true)
    } else {
      const { history } = props
      history.push(`/invest/${data.id}`)
    }
  }, [profile, props.history, data.id])

  const closeQualifiedInfo = useCallback(() => setOpened(false), [])

  useSubscription<SubscriptionOperation>(
    () => ({
      skip: !data.id || status === COMPLETE || status === SUCCEEDED,
      subscription,
      variables: { input: { orderId: data.id } },
      onNext: (data) => {
        setData((prev) => ({
          ...prev,
          ...data.gatheredAmount.order,
          chain: {
            ...prev.chain,
            ...data.gatheredAmount.order.chain,
          },
          viewer: {
            ...prev.viewer,
            ...data.gatheredAmount.order.viewer,
          },
        }))
      },
      // eslint-disable-next-line no-console
      onError: (error) => console.error(error),
    }),
    [data.id],
  )

  return (
    <>
      <QualifiedInfoModal isOpened={isOpened} onClose={closeQualifiedInfo} />

      {status !== COMPLETE && status !== SUCCEEDED && (
        <>
          <DesktopContainer>
            <Container>
              <Timer closingTime={getClosingTime(data)} />
              <StatusBar
                goal={getProposalGoal(data)}
                gatheredAmount={chain.gatheredAmount}
                statusBarValue={getStatusBarValue(data)}
                statusBarMinValue={getStatusBarMinValue(data)}
                minValue={application.data.minValue}
                maxValue={application.data.maxValue}
              />
              <Spring to={chain}>
                {({ investorsCount }) => (
                  <InfoBlock
                    title="Инвесторов"
                    value={divideNumber(investorsCount.toFixed(0))}
                  />
                )}
              </Spring>
              <Spring to={viewer}>
                {({ investedAmount }) => (
                  <InfoBlock
                    title="Вы инвестировали"
                    value={`${divideNumber(investedAmount.toFixed(2))} ₽`}
                  />
                )}
              </Spring>
              {status === CONFIRMED && (
                <InvestmentButton onClick={goToInvest} disabled={isDisabled} />
              )}
            </Container>
            <Divider />
          </DesktopContainer>

          <MobileContainer>
            <Container>
              <StatusBar
                goal={getProposalGoal(data)}
                gatheredAmount={chain.gatheredAmount}
                statusBarValue={getStatusBarValue(data)}
                statusBarMinValue={getStatusBarMinValue(data)}
                minValue={application.data.minValue}
                maxValue={application.data.maxValue}
              />
            </Container>

            <Divider />

            <Container>
              <Timer closingTime={getClosingTime(data)} />
              <Spring to={chain}>
                {({ investorsCount }) => (
                  <InfoBlock
                    title="Инвесторов"
                    value={divideNumber(investorsCount.toFixed(0))}
                    descr="Чел"
                  />
                )}
              </Spring>
              <Spring to={viewer}>
                {({ investedAmount }) => (
                  <InfoBlock
                    title="Инвестиции"
                    value={divideNumber(investedAmount.toFixed(2))}
                    descr="Рублей"
                  />
                )}
              </Spring>
            </Container>

            <Divider />

            <Container>
              {status === CONFIRMED && (
                <InvestmentButton onClick={goToInvest} disabled={isDisabled} />
              )}
            </Container>
          </MobileContainer>
        </>
      )}

      {(status === COMPLETE || status === SUCCEEDED) && (
        <>
          <DesktopContainer>
            <Container>
              <InfoBlock
                title="Дата договора займа"
                value={getLoanDate(data)}
              />
              <InfoBlock
                title="Инвесторов"
                value={divideNumber(chain.investorsCount)}
              />
              <InfoBlock
                title="Погашение долга"
                value={getFinishPaymentDate(data)}
              />
              <InfoBlock
                bold
                title="Вы инвестировали"
                value={`${divideNumber(viewer.investedAmount.toFixed(2))} ₽`}
              />
              <InfoBlock
                bold
                title="Выплачено"
                value={`${divideNumber(
                  getPaymentAmount(data, profileId).toFixed(2),
                )} ₽`}
              />

              {/* TODO: В будущем нужно будет отобразить */}
              {/* {viewer.investedAmount > 0 && status === COMPLETE && (
                <OutlinedButton onClick={null}>Продать заем</OutlinedButton>
              )} */}
            </Container>
            <Divider />
          </DesktopContainer>

          <MobileContainer>
            <Container direction="column">
              <InfoBlock
                title="Дата договора займа"
                value={getLoanDate(data)}
              />
              <InfoBlock
                title="Инвесторов"
                value={divideNumber(chain.investorsCount)}
              />
              <InfoBlock
                title="Погашение долга"
                value={getFinishPaymentDate(data)}
              />
            </Container>

            <Divider />

            <Container>
              <InfoBlock
                bold
                title="Вы инвестировали"
                value={`${divideNumber(viewer.investedAmount.toFixed(2))} ₽`}
              />
              <InfoBlock
                bold
                title="Выплачено"
                value={`${divideNumber(
                  getPaymentAmount(data, profileId).toFixed(2),
                )} ₽`}
              />
            </Container>

            <Divider />

            {/* TODO: В будущем нужно будет отобразить */}
            {/* {viewer.investedAmount > 0 && status === COMPLETE && (
              <Container>
                <OutlinedButton onClick={null}>Продать заем</OutlinedButton>
              </Container>
            )} */}
          </MobileContainer>
        </>
      )}
    </>
  )
}

export default InvestorProposalSpecification
