import React from "react"

import { Translate, MiniLoader } from "src/components"

import { MobileInformationCompany } from "../Elements"

import {
  MobileItemContainer as Container,
  MobileHeaderContainer,
  HeaderWrap,
  MobileInformationContainer,
  MobileInformationItem,
  MobileInformationName,
  MobileInformationValue,
  MobileCompletedStatus,
  CardCover,
  CardButtonsWrapper,
  CardButton,
} from "./styles"

import { ChartBig } from "./icons"

function Head() {
  return (
    <HeaderWrap>
      <MobileHeaderContainer>
        <MobileInformationCompany name="_" />
      </MobileHeaderContainer>
    </HeaderWrap>
  )
}

function Info() {
  return (
    <CardCover>
      <MiniLoader changed fontSize="8px" margin="auto" />
    </CardCover>
  )
}

function Body() {
  return (
    <MobileInformationContainer>
      <MobileInformationItem>
        <MobileInformationName>№ заявки</MobileInformationName>
        <MobileInformationValue>_</MobileInformationValue>
      </MobileInformationItem>
      <MobileInformationItem>
        <MobileInformationName>
          <Translate i18n={"models:loan.rating"} />
        </MobileInformationName>
        <MobileInformationValue>_</MobileInformationValue>
      </MobileInformationItem>
      <MobileInformationItem>
        <MobileInformationName>Сумма</MobileInformationName>
        <MobileInformationValue>0M</MobileInformationValue>
      </MobileInformationItem>
      <MobileInformationItem>
        <MobileInformationName>
          <Translate i18n={"models:loan.rate"} />
        </MobileInformationName>
        <MobileInformationValue>0%</MobileInformationValue>
      </MobileInformationItem>
      <MobileInformationItem>
        <MobileInformationName>Дата получения</MobileInformationName>
        <MobileInformationValue>_</MobileInformationValue>
      </MobileInformationItem>
      <MobileInformationItem>
        <MobileInformationName>Дата погашения</MobileInformationName>
        <MobileInformationValue>_</MobileInformationValue>
      </MobileInformationItem>
      <MobileInformationItem>
        <MobileInformationName>Статус</MobileInformationName>
        <MobileCompletedStatus isSuccess={true}>
          {ChartBig} Погашен в срок
        </MobileCompletedStatus>
      </MobileInformationItem>
    </MobileInformationContainer>
  )
}

function Footer() {
  return (
    <CardButtonsWrapper>
      <CardButton>
        <Translate i18n={"models:loan.buttons.detailed"} />
      </CardButton>
    </CardButtonsWrapper>
  )
}

export default {
  Container,
  Head,
  Info,
  Body,
  Footer,
}
