import styled, { css } from "styled-components"
import { width, minHeight } from "styled-system"

import { statusColors } from "src/components/atoms/Enums/statusColors"
import { Icons } from "src/components"

import { NavLink } from "react-router-dom"

const backgroundColor = ({ theme, status }) => `background-color: ${theme.colors[statusColors[status]]};`

export const GraphButton = styled.button`
  background-color: white;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.35);
  border-radius: 2px;
  cursor: pointer;
  margin-left: 8px;
  width: 24px;
  height: 24px;
  padding: 4px 0 0;
  vertical-align: bottom;

  &:focus {
    outline: 0;
  }

  @media screen and (max-width: 800px) {
    box-shadow: none;
    width: 30px;
    height: 30px;
    padding: 4px;
    border: 1px solid rgba(74, 74, 74, 0.2);
    box-sizing: border-box;
    border-radius: 2px;
  }
`

export const CalculatorIcon = styled(Icons.Calculator)`
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.colors.primaryBlack};

  @media screen and (max-width: 800px) {
    width: 27px;
    height: 27px;
    fill: white;
  }
`

export const ClockIcon = styled(Icons.Clock)`
  width: 13px;
  height: 13px;
  fill: ${({ theme }) => theme.colors.primaryBlack};
  vertical-align: text-top;
  margin-right: 4px;
`

export const OrderDate = styled.span`
  font-weight: 300;
  color: ${({ theme }) => theme.colors.primaryBlacks[7]};
`

export const TableTDSchedule = styled.td``

export const CardCover = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
`

export const MobileInformationName = styled.div`
  font-size: 20px;
  position: relative;
  margin-bottom: 4px;
  font-weight: 300;
  text-align: left;
  color: ${({ theme }) => theme.colors.primaryBlacks[7]};
`

export const CloseIcon = styled(Icons.Close)`
  width: 24px;
  height: 24px;
  fill: white;
`

export const CloseButton = styled.button`
  border: none;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
`

export const MobileWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const MobileHeaderContainer = styled.div`
  box-sizing: border-box;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

export const MobileInformationContainer = styled.div`
  box-sizing: border-box;
  margin-bottom: 32px;
`

export const MobileDescriptionTitle = styled.h4`
  font-family: Geometria, sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  margin: 0;
  font-weight: 300;
`

export const MobileDescription = styled.p`
  font-family: Geometria, sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  margin: 0;
  font-weight: 600;
`

export const MobileInformationItem = styled.div`
  display: flex;
  justify-content: space-between;
`

export const MobileInformationValue = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: right;
`

export const DiagramIcon = styled(Icons.Diagram)`
  width: 24px;
  height: 24px;
  fill: white;
  margin-right: 8px;
`

export const MobileGoalItem = styled.div`
  width: 100%;
  margin-top: auto;
`

export const MobileGoalValue = styled.div`
  font-size: 20px;
  text-align: right;
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`

export const MobileGoalBar = styled.div`
  width: 100%;
`

export const CardButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`

export const CardButton = styled.button<any>`
  height: 65px;
  padding: 0;
  width: ${(props) => (props.compact && "45%") || "100%"};
  text-align: center;
  border: ${(props) => (props.primary && "2px solid #F70000") || "2px solid #4a4a4a"};
  color: ${(props) => (props.primary && "#F70000") || "#4a4a4a"};
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #ffffff;
  text-transform: uppercase;

  @media screen and (max-width: 479px) {
    width: 100%;
    &:last-child {
      margin-top: 20px;
    }
  }
`

export const ButtonLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  text-transform: inherit;
`

export const MobileButton = styled.button<any>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  box-sizing: border-box;
  border: 2px solid white;
  border-radius: 4px;
  color: white;
  font-family: Geometria, sans-serif;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  padding: 0 8px;
  ${width};

  :not(:last-of-type) {
    margin-right: 8px;
  }

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media screen and (max-width: 400px) {
    font-size: 11px;
  }

  @media screen and (max-width: 370px) {
    font-size: 10px;
  }

  @media screen and (max-width: 340px) {
    font-size: 9px;
  }
`

export const PrimaryMobileButton = styled(MobileButton)`
  background-color: ${({ theme }) => theme.colors.mainRed};
  border: none;
`

export const MobileCompletedStatus = styled.div<any>`
  color: ${(props) => (props.isSuccess && "#6FA84B") || "#F70000"};
  svg path {
    fill: ${(props) => (props.isSuccess && "#6FA84B") || "#F70000"};
  }
  font-size: 24px;
  font-weight: bold;
  text-align: right;
`

export const ItemContainer = styled.div`
  position: relative;
  border-radius: 5px;
  width: 100%;
  padding: 35px 30px 30px;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  ${backgroundColor}

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const ItemContainerActive = styled.div`
  font-family: Geometria;
  width: 360px;
  height: 485px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  margin-bottom: 40px;
  @media screen and (min-width: 1280px) {
    margin-right: 40px;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 360px) {
    width: 100%;
    margin-right: 0;
  }
  @media screen and (min-width: 360px) and (max-width: 719px) {
    margin-right: 0px;
  }
  @media screen and (min-width: 719px) and (max-width: 840px) {
    margin-right: 20px;
    margin-right: 20px;
  }
  @media screen and (min-width: 840px) and (max-width: 959px) {
    margin-left: 0;
    margin-right: 0;
  }
  @media screen and (min-width: 960px) and (max-width: 1279px) {
    margin-right: 100px;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
`

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 20px 0 0;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
`

export const HeaderContainerActive = styled.div`
  padding-top: 15px;
  padding-bottom: 18px;
  padding-left: 22px;
  padding-right: 22px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.55);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 160px;
`
export const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  padding: 0 0 30px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`

export const HeaderWrap = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: 24px;
`

export const InformationContainer = styled.div`
  background-color: #f7f7f7;
  padding: 22px 0;
  color: #4a4a4a;
  display: flex;
  justify-content: space-around;
  text-align: center;
  box-sizing: border-box;
`
export const InformationItem = styled.div``
export const InformationName = styled.div`
  font-size: 11px;
  line-height: 14px;
  min-width: 70px;
  position: relative;
  margin-bottom: 4px;
  font-weight: 300;
`
export const InformationValue = styled.div`
  font-size: 14px;
  line-height: 18px;
`
export const TooltipWrap = styled.div`
  display: block;
  position: absolute;
  z-index: 99;
  background-color: #4a4a4a;
  padding-top: 6px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 6px;
  border-radius: 4px;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
  width: 194px;
  box-sizing: border-box;
  text-align: left;
  top: 16px;
  a {
    color: #ffffff;
  }
  &:before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background-color: #4a4a4a;
    position: absolute;
    transform: rotate(45deg);
    top: -3px;
    left: 56px;
  }
`
export const BodyContainer = styled.div`
  padding-top: 54px;
  padding-bottom: 43px;
  color: #4a4a4a;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
`
export const BodyGoal = styled.div`
  display: flex;
  justify-content: space-between;
`

export const BodyGoalItem = styled.div``
export const BodyGoalItemValue = styled.div`
  font-size: 18px;
  line-height: 23px;
  font-weight: bold;
`
export const BodyGoalItemDescr = styled.div`
  font-size: 11px;
  line-height: 14px;
  font-weight: 300;
`
export const FooterActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
`
export const FooterActionItem = styled.div``
export const FooterActionItemBtn = styled.div`
  display: flex;
  text-align: center;
  width: 100%;
`
export const ActionLink = styled.a`
  font-size: 11px;
  line-height: 14px;
  color: #393939;
  padding: 10px;
  text-decoration: none;
  box-sizing: border-box;
  font-weight: bold;
  padding-left: 0;
`
export const ActionLinkBtn = styled.a`
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  border: 1px solid #434343;
  border-radius: 5px;
  width: 100%;
  color: #393939;
  padding: 10px;
  text-decoration: none;
  box-sizing: border-box;
`
export const ActionButton = styled.a<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 110px;
  height: 45px;
  background: #f70000;
  box-shadow: 0 5px 10px rgba(255, 116, 143, 0.55);
  border-radius: 4px;
  font-family: Geometria, sans-serif;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  text-decoration: none;

  ${(props) => props.size === "min"
    && css`
      font-size: 10px;
      line-height: 15px;
      width: 110px;
      padding: 15px 0;
    `}
`

export const GoalInfo = styled.div`
  display: flex;
  justify-content: space-between;
`

export const GoalInfoItem = styled.div`
  display: flex;
  align-items: center;
`

export const GoalInfoIcon = styled.div`
  margin-right: 6px;
`

export const GoalInfoValue = styled.div`
  text-transform: lowercase;
`

export const DescriptionContainer = styled.div`
  height: 397px;
  margin-bottom: 24px;
  background: #f7f7f7;
  color: #4a4a4a;
  position: relative;
  padding-left: 21px;
  padding-right: 21px;
  padding-bottom: 27px;
  padding-top: 21px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const DescriptionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DescriptionExit = styled.div`
  cursor: pointer;
`

export const DescriptionTitle = styled.div`
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;
`

export const DescriptionBody = styled.div`
  font-size: 14px;
  line-height: 18px;
  height: 214px;
  margin-bottom: 37px;
  box-sizing: border-box;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 12;
  overflow: hidden;
`

export const DescriptionSchedule = styled.div``
export const DescriptionScheduleItem = styled.div``
export const DescriptionScheduleName = styled.div`
  font-weight: bold;
  display: inline;
`
export const DescriptionScheduleValue = styled.div`
  display: inline;
`

export const TableYoutube = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const Wrap = styled.div`
  height: 421px;
`

export const TableRow = styled.tr`
  font-family: Geometria, sans-serif;
  font-size: 16px;
  line-height: 20px;
  height: 76px;
  vertical-align: middle;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  color: #4a4a4a;
  @media screen and (max-width: 1279px) {
    font-size: 15px;
    line-height: 20px;
  }
  @media screen and (max-width: 990px) {
    font-size: 14px;
  }

  &:hover {
    box-shadow: 0 1px 9px rgba(0, 0, 0, 0.1);
  }
`
export const TableTdName = styled.td`
  width: 280px;
  box-sizing: border-box;
  overflow: hidden;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  @media screen and (max-width: 1279px) {
    font-size: 16px;
    line-height: 20px;
    width: 200px;
  }
`
export const TableTdNameWrap = styled.div`
  width: 280px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #4a4a4a;

  &:hover {
    opacity: 0.65;
  }

  @media screen and (max-width: 1279px) {
    width: 200px;
  }
`

export const RepaymentType = styled.div`
  display: inline-block;
  min-width: 72px;
`

export const OrderTypeTd = styled.td`
  text-transform: uppercase;
`

export const TableTdRating = styled.td`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
`
export const TableTdSum = styled.td`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
`
export const TableTdRate = styled.td`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
`

export const CalendarIcon = styled(Icons.Calendar)`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  vertical-align: top;
`

export const TableTdStatus = styled.td<any>`
  color: ${({ theme, isSuccess }) => (isSuccess && "#6FA84B") || theme.colors.mainRed};

  ${CalendarIcon} {
    fill: ${({ theme, isSuccess }) => (isSuccess && "#6FA84B") || theme.colors.mainRed};
  }
`

export const DebtLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.mainRed};

  &:hover {
    opacity: 0.65;
  }

  @media screen and (max-width: 800px) {
    color: ${({ theme }) => theme.colors.white};
  }
`

export const Flipper = styled.div`
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
`

export const FlipContainer = styled.div<any>`
  perspective: 1000px;

  ${({ isClicked }) => isClicked
    && css`
      ${Flipper} {
        transform: rotateY(180deg);
      }
    `};
`

export const MobileItemContainer = styled.div<any>`
  position: relative;
  font-family: Geometria, sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 40px;
  min-height: 300px;
  ${minHeight};
`

const FlipperFace = styled.div`
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  padding: 30px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.19);
  min-height: 300px;
  display: flex;
  flex-direction: column;
  ${minHeight};
`

export const FlipperFront = styled(FlipperFace)<any>`
  z-index: 2;
  color: ${({ theme }) => theme.colors.primaryBlack};
`

export const FlipperBack = styled(FlipperFace)<any>`
  transform: rotateY(180deg);
  background-color: #757575;
  color: white;

  ${MobileInformationName} {
    color: white;
  }
`
